/**
 * implantations
 */

.slide-enter-active {
  transition: opacity .5s;
  @include media-breakpoint-up(lg) {
    transition: all .5s;
  }
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  @include media-breakpoint-up(lg) {
    transform: translateY(-20%);
  }
}

.fade-enter-active{
  transition: opacity .5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.implantations-container {
  @include media-breakpoint-up(md) {
    min-height: 67rem;
  }
  @include media-breakpoint-up(lg) {
    min-height: 67rem;
  }
  @include media-breakpoint-up(xl) {
    min-height: 53rem;
  }
  @include media-breakpoint-up(xxl) {
    min-height: 53rem;
  }
}

.implantations-container-contact {
  @include media-breakpoint-up(md) {
    min-height: 67rem;
  }
}

.implantations-results {
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }
}
.implantations-results--2 {
  @include media-breakpoint-up(xxl) {
    padding-right: 0 !important;
    padding-left: 7rem !important;
    .tns-controls {
      position: relative;
      z-index: 800;
      margin-top: -1.6rem;
      padding-top: 0 !important;
      padding-right: 4rem;
    }
  }
}

.bg-splash {
  &__bg {
    img {
      object-position: 100% 100%;
      object-fit: contain;
    }
  }
}

.st0 {
  fill: #D4D3D7;
  stroke: var(--bs-white);
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st1 {
  fill: #D4D3D7;
}
.st2 {
  fill: none;
  stroke: var(--bs-white);
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st3 {
  cursor: pointer;
  transition: all .2s ease-in-out;
  fill: var(--bs-purple);
  stroke: var(--bs-white);
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
a.st3 {
  path {
    cursor: pointer;
    transition: all .2s ease-in-out;
    fill: var(--bs-purple);
    stroke: var(--bs-white);
    stroke-width: 0.5;
    stroke-miterlimit: 10;
  }
  &:hover,
  &:focus {
    path {
      fill: $blue;
    }
  }
}

.st7 {
  cursor: pointer;
  transition: all .2s ease-in-out;
  fill: #D4D3D7;
  stroke: var(--bs-white);
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
a.st7 {
  path {
    cursor: pointer;
    transition: all .2s ease-in-out;
    fill: #D4D3D7;
    stroke: var(--bs-white);
    stroke-width: 0.5;
    stroke-miterlimit: 10;
  }
}
