/**
 * tile news
 */

.tile-news {
  $parent: &;
  height: 32rem;
  margin: 0 0 $grid-gutter-width;
  &__inner {
    padding: ($grid-gutter-width * .5);
  }
  &__pic {
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background: linear-gradient(225deg, #008ACD 0%, #764896 50.50%, #D61036 100%);
    img {
      object-fit: cover;
      transition: all .5s ease;
      transform: scale(1.3);
      mix-blend-mode: lighten;
      z-index: 2;
    }
    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &::before {
      height: 100%;
      background: linear-gradient(225deg, #008ACD 0%, #764896 50.50%, #D61036 100%);
      opacity: .7;
      z-index: 5;
    }
    &::after {
      height: 50%;
      background: linear-gradient(0deg, #25272F 0%, rgba(37, 39, 47, 0.00) 100%);
      z-index: 6;
    }
  }
  &__limit {
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    overflow: hidden;
  }
  &__title {
    + #{$parent}__excerpt {
      #{$parent}__limit {
        /* autoprefixer: off */
        -webkit-line-clamp: 3;
        /* autoprefixer: on */
      }
    }
  }
  &__excerpt {
    flex: 0 0 28%;
    height: 28%;
    transition: all .5s ease;
  }

  &__date {

  }
  &__arrow {
    width: 2.5rem;
    height: 2.5rem;
    transform: translate(-5rem, -2.5rem);
    opacity: 0;
    transition: all .6s ease;
    .icon {
      width: 100%;
      height: 100%;
      stroke: currentColor;
    }
  }
  [class*="cell"] > & {
    margin-right: auto;
    margin-left: auto;
  }
  // [class*="col"] > & {
  //   height: calc(100% - #{$grid-gutter-width});
  // }
  &--nogradient {
    #{$parent}__pic {
      background: var(--bs-dark);
      &::before {
        display: none !important;
      }
    }
  }
  .section-home & {
    margin-bottom: 0 !important;
  }
  @include media-breakpoint-up(lg) {
    &__inner {
      padding: ($grid-gutter-width * .75);
    }
    &:hover,
    &:focus {
      #{$parent}__pic {
        > img {
          transform: scale(1);
        }
      }
      #{$parent}__excerpt {
        flex: 0 0 41%;
      }
      #{$parent}__arrow {
        transform: translate(-2.5rem, -2.5rem);
        opacity: 1;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    height: 42rem;
    &__arrow {
      transform: translate(-6rem, -3rem);
    }
    &:hover,
    &:focus {
      #{$parent}__excerpt {
        flex: 0 0 35%;
      }
      #{$parent}__arrow {
        transform: translate(-3rem, -3rem);
      }
    }
  }
}
