/**
 * section home
 */



.section-home-background {
  height: 100vh;
  z-index: 0;
  // z-index: 15;
  video {
    object-fit: cover;
  }
}

.home-navigation {
  margin-bottom: calc(1em + 9vh);
  &::before {
    content: '';
    display: block;
    width: 1px;
    height: calc(var(--durationHeight) - 2rem);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    transform: translate(-2px, 1.5rem);
    background: var(--bs-white);
    transition: all .5s ease;
  }
  &-wrapper {
    z-index: 15;
    padding-top: 80px;
    padding-bottom: calc(80px + 16vh);
  }
  &__list {
    position: relative;
    z-index: 15;
  }
  &__item {
    padding-bottom: calc(1em + 3vh);
    &::before,
    &::after {
      content: '';
      display: block;
      background: var(--bs-white);
      position: absolute;
      right: 0;
    }
    &::before {
      width: 1px;
      height: calc(100% - 1.5rem);
      top: 1rem;
      transform: translate(-2px, 1.1rem);
      opacity: .2;
    }
    &::after {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      top: .8em;
    }
    &:last-child {
      padding-bottom: 0;
      &::before {
        display: none;
      }
    }
  }
  &__button {
    padding-right: 2.25rem;
    transition: all .2s ease-in-out;
    &:active {
      font-weight: 800;
    }
  }
  @include media-breakpoint-up(xl) {
    @media screen and (min-height: 500px) {
      &-wrapper {
        padding-bottom: 110px;
      }
    }
  }
}

.section-home {
  $parent: &;
  position: relative;
  min-height: 100vh;
  .main-intro__bg {
    z-index: 0;
  }
  @include media-breakpoint-down(lg) {
    &__splash {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100vh;
      padding-top: 80px;
      padding-bottom: calc(80px + 16vh);
    }
    &__intro,
    &__cta {
      &::before {
        content: '';
        display: block;
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 0;
        background-color: var(--bs-white);
      }
      &-inner {
        z-index: 5;
      }
    }
    &__intro {
      padding-top: $grid-gutter-width;
      font-size: 16px;
      color: var(--bs-dark);
      &:last-child {
        padding-bottom: $grid-gutter-width;
      }
    }
    &__cta {
      padding-bottom: ($grid-gutter-width * 1.7);
      text-align: center;
    }
    .regular-control[data-controls].btn-round {
      color: var(--bs-dark);
    }
  }
  @include media-breakpoint-up(lg) {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transform: translate(0, 25%);
    transition: all 1s ease;
    &__content {
      height: 100vh;
      padding-top: 80px;
      padding-bottom: calc(80px + 16vh);
    }
    > * {
      pointer-events: none !important;
    }
    &.current-section {
      opacity: 1;
      pointer-events: initial;
      z-index: 5;
      transform: translate(0, 0);
      > * {
        pointer-events: initial !important;
      }
    }
    @media screen and (min-height: 500px) {
      &__content {
        padding-top: 110px;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    @media screen and (min-height: 500px) {
      &__content {
        padding-bottom: 110px;
      }
    }
  }
  @include media-breakpoint-up(xxl) {

  }
  @media screen and (max-height: 650px) {
    h1 {
      font-size: 4.6rem;
    }
  }
  @media screen and (max-height: 500px) {
    h1 {
      font-size: 4rem;
    }
  }
}

.home-h2 {
  margin-bottom: .5em;
  font-weight: 800;
  @include font-size(5rem);
  line-height: 1.5;
  @include media-breakpoint-up(lg) {
    line-height: 1.35;
  }
}


.fake-scroll-area-wrapper {
  width: 100vw;
  z-index: 25;
}
