/**
 * tables
 */

.table-wrapper {
  margin: 2em 0 3em;
  h2 + & {
    margin-top: -2.2em;
  }
  &__slide {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }
}


table:not([class]) {
  width: calc(100% - 1px);
  margin: 0;
  caption {
    position: absolute !important;
    height: 1px; width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    bottom: 0;
  }
  th, td {
    padding: .85em 2rem;
    font-weight: 400;
    &:last-child {
      border-right: none;
    }
  }
  thead {
    th, td {
      @extend .text-white;
      @extend .text-center;
      background-color: var(--bs-gray-600);
      border-right: 1px solid var(--bs-gray-300);
      font-weight: 500;
    }
  }
  tbody {
    // tr {
    //   border-right: 1px solid var(--bs-gray-300);
    //   border-left: 1px solid var(--bs-gray-300);
    // }
    th, td {
      border-right: 1px solid var(--bs-gray-300);
      border-bottom: 1px solid var(--bs-gray-300);
      vertical-align: top;
      P {
        margin: 0;
      }
    }
    th {
      font-weight: 800;
      color: $body-color;
    }
    td {

    }
  }
  // .date {
  //   font-weight: 500;
  //   font-size: 1.2rem;
  //   text-transform: uppercase;
  //   color: var(--bs-primary);
  // }
  // .reference {
  //   font-family: $headings-font-family;
  //   font-weight: 500;
  //   font-size: 2.1rem;
  //   color: $gray-600;
  // }
}
