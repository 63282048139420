/**
 * overriding and extending bootstrap
 */


// .form-check-input {
//   &:checked {
//     background-color: var(--bs-primary);
//     border-color: var(--bs-primary);
//   }
//   margin-top: .3rem;
// }
p b,
p strong {
  color: currentColor !important;
}
