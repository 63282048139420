/**
 * lists
 */

ul:not([class]),
ol:not([class]) {
  @include resetUl;
  margin-top: 1.8em;
  .twocols & {
    @include media-breakpoint-up(md) {
      columns: 2;
    }
  }
  [class*="col"] > & {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

ul:not([class]) {
  margin-bottom: 1.6em;
  > li {
    position: relative;
    padding-left: 1em;
    &::before {
      content: '';
      display: block;
      width: .7rem;
      height: .7rem;
      border-radius: 100%;
      background-color: var(--bs-red);
      position: absolute;
      top: .5em;
      left: 0;
    }
    ul {
      margin-top: 0;
      margin-bottom: 1em;
      padding-left: .7em;
      li {
        padding-left: 1.6rem;
        &::before {
          content: '';
          display: block;
          width: .6rem;
          height: 1px;
          background-color: var(--bs-secondary);
          top: .75em;
          left: .25em;
          border-radius: none;
        }
      }
    }
  }
}

ol:not([class]) {
  margin-bottom: 1.2em;
  counter-reset: step-counter;
  > li {
    position: relative;
    padding-left: 1.3em;
    > p,
    > h3:first-child,
    > h4:first-child {
      display: inline-block;
      margin-bottom: 1.5rem;
      font-size: $font-size-base;
      color: var(--bs-body-color);
    }
    &::before {
      counter-increment: step-counter;
      content: counter(step-counter)'\00A0\002F\00A0';
      font-weight: 700;
      color: var(--bs-primary);
      // display: inline;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
    }
    &:last-child {
      ol {
        margin-bottom: 0;
      }
    }
    ol {
      counter-reset: listStyle;
      margin-top: 0;
      margin-bottom: 1em;
      padding-left: .1em;
      li {
        counter-increment: listStyle;
        padding-left: 1.5em;
        &::before {
          content: counter(listStyle, lower-alpha)'\00A0\002F\00A0';
          font-weight: 800;
          color: var(--bs-body-color);
        }
      }
    }
    ul {
      margin-top: .8em;
      margin-bottom: 1.45em;
      padding-left: 1.4em;
      > li {
        &::before {
          content: '\002D';
          display: inline-block;
          background: transparent;
          top: 0;
          width: auto;
          height: auto;
          border-radius: none;
          top: 0;
        }
      }
    }

  }
}

.invisible-list,
.inline-list {
  @include resetUl;
}
.inline-list {
  li {
    display: inline-block;
  }
}


// .ordered-fancy {
//   margin: 2em 0;
//   padding: 0;
//   list-style: none;
//   li {
//     counter-increment: count;
//     margin-bottom: 4px;
//     min-height: 78px;
//     > * {
//       padding: 1.25em 1.4em;
//     }
//     a {
//       &:hover,
//       &:focus {
//         text-decoration: underline !important;
//       }
//     }
//   }
//   li::before {
//     display: block;
//     content: counter(count);
//     flex: 0 0 8rem;
//     width: 8rem;
//     padding: .1em 0 0;
//     background-color: var(--bs-secondary);
//     font-family: $headings-font-family;
//     font-weight: 300;
//     font-size: 4rem;
//     color: var(--bs-white);
//     text-align: center;
//   }
//   li:nth-child(-n+9)::before {
//     content: '0'counter(count);
//   }
// }
