.field {
    &__switch {
        $self: &;
        position: relative;
        display: inline-block;
        width: 49px;
        height: 22px;
        cursor: pointer;

        &-checkbox {
            top: 0;
            left: 0;
            width: 100%;
            cursor: inherit;
            margin: 0;
            opacity: 0;
            padding: 0;
            z-index: 1;
            position: absolute;

            &:checked {
                ~ #{$self}-slider {
                    background-color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));

                    &:before {
                        -webkit-transform: translateX(27px);
                        -ms-transform: translateX(27px);
                        transform: translateX(27px);
                    }
                }
            }
        }

        &-slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #F3F3F3;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 34px;

            &:before {
                position: absolute;
                content: "";
                height: 22px;
                width: 22px;
                left: 0;
                bottom: 0;
                background-color: white;
                border: 1px solid #DEDFDF;
                box-shadow: 0px 1px 3px rgba($black, 0.2);
                -webkit-transition: .4s;
                transition: .4s;
                border-radius: 50%;
                cursor: pointer;
            }
        }
    }
}
