/**
 * odd intro
 */


.odd-intro {
  $parent: &;
  height: 378px;
  max-height: calc(100vh - 60px);
  padding: 89px 0 0;
  &__bg {
    z-index: -1;
    background: var(--bs-white);
    img {
      position: relative;
      z-index: -1;
      // mix-blend-mode: lighten;
      // backdrop-filter: blur(7.5px);
      object-position: 50% 0;
      object-fit: cover;
      transform: scale(1.1);
      // transition: all .6s ease;
    }
    + * {
      z-index: 5;
    }
  }
  &__inner {
    z-index: 5;
  }
  &__hr {
    bottom: 10%;
    left: 0;
    z-index: 5;
    transition: opacity .2s ease;
    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 5rem;
      background-color: var(--bs-white);
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 5;
      transform: translate(-50%, 0);
      transition: all .6s ease;
    }
    hr {
      opacity: 0;
    }
    .header-sticky--active & {
      opacity: 0;
    }
  }
  .pageload & {
    #{$parent}__bg {
      img {
        transform: scale(1);
      }
    }
  }
  @include media-breakpoint-up(sm) {

  }
  @include media-breakpoint-up(md) {
    height: 678px;
    padding-top: 109px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 139px;
    &__bg {
      img {
        object-fit: contain;
      }
    }
    @media screen and (max-height: 700px) {
      min-height: 40rem;
      h1 {
        margin-top: 1vh;
        margin-bottom: 1vh;
      }
      &__hr {
        &::after {
          height: 6.8vh;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    padding-top: 152px;
  }
  @include media-breakpoint-up(xxl) {
    padding-top: 166px;
  }
  @media screen and (min-width: 1440px) {

  }
}

