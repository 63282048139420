// ###########################################
// ######## MIXINS, PLACEHOLDERS & FX ########
// ###########################################

// fonts
@mixin embed-font($font-name, $font-filepath-and-name, $font-weight, $font-style, $woff2: false){
  @font-face {
    font-family: $font-name;
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: swap;
    @if $woff2 == 'true' {
    src: url(#{$font-filepath-and-name}.woff2) format('woff2'),
         url(#{$font-filepath-and-name}.woff) format('woff');
    } @else {
    src: url(#{$font-filepath-and-name}.woff) format('woff');
    }
  }
}

// reset list
@mixin resetUl($margin: 0, $padding: 0) {
  margin: $margin;
  padding: $padding;
  list-style: none;
  padding-inline-start: 0;
}

.drop-shadow {
  box-shadow: $box-shadow;
}

%imgcover {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

%icon {
  display: block;
  width: 100%;
  height: 100%;
  fill: currentColor;
}

%cardLabel {
  display: inline-block;
  padding: .65rem 1.3rem .85rem;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 100%;
  background-color: var(--bs-pink);
  border-radius: $border-radius;
  color: var(--bs-white);
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 5;
  transform: translate3d(-50%, -50%, 0);
}

%legend {
  position: relative;
  display: block;
  margin: 0 0 0 2rem;
  padding: .75em 0 0 2rem;
  font-size: 1.6rem;
  color: var(--bs-gray-500);
  &::before {
    content: '';
    display: block;
    width: 2px;
    height: 180%;
    background-color: var(--bs-primary);
    position: absolute;
    bottom: -.65em;
    left: 0;
    opacity: .8;
  }
  @include media-breakpoint-up (md) {
    padding-left: 4rem;
    &::before {
      left: 2rem;
    }
  }
}

%playIcon {
  top: 50%;
  left: 50%;
  z-index: 60;
  width: 5rem;
  height: 5rem;
  background-color: rgba($dark, .7);
  color: var(--bs-white);
  padding: .5rem .3rem .5rem .1rem;
  transition: all .2s ease-in-out;
  transform: translate(-50%, -50%);
  .icon {
    &--play {
      @extend %icon;
      padding: 6.5px;
    }
  }
}

%linearGradient1 {
  background: linear-gradient(270deg, #008ACD 0%, #764896 50.50%, #D61036 100%);
  backdrop-filter: blur(7.5px);
}
%linearGradient2 {
  background: linear-gradient(225deg, #008ACD 0%, #764896 50.50%, #D61036 100%);
  backdrop-filter: blur(7.5px);
}
