.spinner {
    display: block;
    position: sticky;
    margin: 5rem 0;
    top: 50%;
    left: 50%;
    height: 150px;
    width: 150px;
    background-image: url('../../dist/images/load.svg');
    background-repeat: no-repeat;
    background-size: 150px;
    background-position: 0 0;
    transform: translate(-50%, -50%);

    &-wrapper {
        background: rgba(255, 255, 255, 0.7);
        height: 100%;
    }
}