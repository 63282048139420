/**
 * fonts
 */

// @import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

// // Inter Tight
// @include embed-font('Baskerville', '../fonts/inter-tight-v1-latin-700', 700, normal, true);
// @include embed-font('Baskerville', '../fonts/inter-tight-v1-latin-700italic', 700, italic, true);
// @include embed-font('Baskerville', '../fonts/inter-tight-v1-latin-600', 600, normal, true);
// @include embed-font('Baskerville', '../fonts/inter-tight-v1-latin-600italic', 600, italic, true);
// @include embed-font('Baskerville', '../fonts/inter-tight-v1-latin-500', 500, normal, true);
// @include embed-font('Baskerville', '../fonts/inter-tight-v1-latin-500italic', 500, italic, true);
// @include embed-font('Baskerville', '../fonts/inter-tight-v1-latin-regular', regular, normal, true);
// @include embed-font('Baskerville', '../fonts/inter-tight-v1-latin-italic', 700, italic, true);

@include embed-font('Segoe Script', '../fonts/segoesc', 400, normal, false);

// Font
$cache-buster: "2024_02_07_15_35";

// Font icon
$icon-font-family: "AirvanceIcon";

@font-face {
    font-family: $icon-font-family;
    src:
    url('../../dist/fonts/AirvanceIcon/AirvanceIcon.ttf?#{$cache-buster}') format('truetype'),
    url('../../dist/fonts/AirvanceIcon/AirvanceIcon.woff?#{$cache-buster}') format('woff'),
    url('../../dist/fonts/AirvanceIcon/AirvanceIcon.svg?#{$cache-buster}##{$icon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"],
.airvance-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $icon-font-family !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icon-window-select: "\e900";
$icon-book: "\e901";
$icon-wind-calculator: "\e902";
$icon-computer: "\e903";
$icon-house-solar-panel: "\e904";
$icon-energy-solar: "\e905";
$icon-earth-care: "\e906";
$icon-arrow-left: "\e907";
$icon-arrow-right: "\e908";
$icon-arrow-down: "\e909";
$icon-external-link: "\e90a";
$icon-search: "\e90b";
$icon-facebook: "\e90c";
$icon-linkedin: "\e90d";
$icon-youtube: "\e90e";
$icon-chef-hat: "\e90f";
$icon-first-aid: "\e910";
$icon-house: "\e911";
$icon-factory: "\e912";
$icon-minus: "\e913";
$icon-plus: "\e914";
$icon-simple-arrow-right: "\e915";
$icon-file: "\e916";
$icon-simple-arrow-left: "\e917";
$icon-logo: "\e918";
$icon-burger: "\e919";
$icon-close: "\e91a";
$icon-contact_chat: "\e91d";
$icon-contact_phone: "\e91e";
$icon-edit: "\e91f";
$icon-location_alt: "\e925";
$icon-location_earth: "\e926";
$icon-human_growth: "\e91b";
$icon-blow: "\e91c";
$icon-factory_alt: "\e920";
$icon-fan: "\e921";
$icon-fire: "\e922";
$icon-lamp_brain: "\e923";
$icon-lamp_flower: "\e924";
$icon-refresh: "\e927";
$icon-air_conditioner: "\e928";
$icon-chef: "\e929";
$icon-filtration: "\e92a";
$icon-exchange: "\e92b";
$icon-y_pipe: "\e92c";
$icon-fan_alt_2: "\e92d";
$icon-fan_alt: "\e92e";
$icon-vmc: "\e92f";
$icon-express: "\e930";
$icon-click: "\e931";
$icon-hand-shake: "\e932";
$icon-calendar: "\e933";
$icon-hand-euro: "\e934";
$icon-box: "\e935";
$icon-delivery: "\e936";
$icon-file-history: "\e937";
$icon-hotline: "\e938";
$icon-upload: "\e939";
$icon-pvc-pipes: "\e93a";
$icon-delivery-return: "\e93b";
$icon-hand-screw: "\e93c";
$icon-star: "\e93d";
$icon-download: "\e93e";
$icon-box-express: "\e93f";
$icon-mobile-app: "\e940";
$icon-comptoir-express: "\e941";
$icon-hand-file: "\e942";
$icon-tech-doc: "\e943";
$icon-shopping-cart: "\e944";
$icon-air-connect: "\e945";
$icon-glossary: "\e946";
$icon-book-download: "\e947";
$icon-tools: "\e948";
$icon-bookmark-file: "\e949";
$icon-hammer-file: "\e94a";
$icon-sav: "\e94b";
$icon-video-tuto: "\e94c";
$icon-webinar: "\e94d";
$icon-e-shop: "\e94e";

.icon-window-select:before {
    content: $icon-window-select;
}

.icon-book:before {
    content: $icon-book;
}

.icon-wind-calculator:before {
    content: $icon-wind-calculator;
}

.icon-computer:before {
    content: $icon-computer;
}

.icon-house-solar-panel:before {
    content: $icon-house-solar-panel;
}

.icon-energy-solar:before {
    content: $icon-energy-solar;
}

.icon-earth-care:before {
    content: $icon-earth-care;
}

.icon-arrow-left:before {
    content: $icon-arrow-left;
}

.icon-arrow-right:before {
    content: $icon-arrow-right;
}

.icon-arrow-down:before {
    content: $icon-arrow-down;
}

.icon-external-link:before {
    content: $icon-external-link;
}

.icon-search:before {
    content: $icon-search;
}

.icon-facebook:before {
    content: $icon-facebook;
}

.icon-linkedin:before {
    content: $icon-linkedin;
}

.icon-youtube:before {
    content: $icon-youtube;
}

.icon-chef-hat:before {
    content: $icon-chef-hat;
}

.icon-first-aid:before {
    content: $icon-first-aid;
}

.icon-house:before {
    content: $icon-house;
}

.icon-factory:before {
    content: $icon-factory;
}

.icon-minus:before {
    content: $icon-minus;
}

.icon-plus:before {
    content: $icon-plus;
}

.icon-simple-arrow-right:before {
    content: $icon-simple-arrow-right;
}

.icon-file:before {
    content: $icon-file;
}

.icon-simple-arrow-left:before {
    content: $icon-simple-arrow-left;
}

.icon-logo:before {
    content: $icon-logo;
}

.icon-burger:before {
    content: $icon-burger;
}

.icon-close:before {
    content: $icon-close;
}

.icon-contact_chat:before {
    content: $icon-contact_chat;
}

.icon-contact_phone:before {
    content: $icon-contact_phone;
}

.icon-edit:before {
    content: $icon-edit;
}

.icon-location_alt:before {
    content: $icon-location_alt;
}

.icon-location_earth:before {
    content: $icon-location_earth;
}

.icon-human_growth:before {
    content: $icon-human_growth;
}

.icon-blow:before {
    content: $icon-blow;
}

.icon-factory_alt:before {
    content: $icon-factory_alt;
}

.icon-fan:before {
    content: $icon-fan;
}

.icon-fire:before {
    content: $icon-fire;
}

.icon-lamp_brain:before {
    content: $icon-lamp_brain;
}

.icon-lamp_flower:before {
    content: $icon-lamp_flower;
}

.icon-refresh:before {
    content: $icon-refresh;
}

.icon-air_conditioner:before {
    content: $icon-air_conditioner;
}

.icon-chef:before {
    content: $icon-chef;
}

.icon-filtration:before {
    content: $icon-filtration;
}

.icon-exchange:before {
    content: $icon-exchange;
}

.icon-y_pipe:before {
    content: $icon-y_pipe;
}

.icon-fan_alt_2:before {
    content: $icon-fan_alt_2;
}

.icon-fan_alt:before {
    content: $icon-fan_alt;
}

.icon-vmc:before {
    content: $icon-vmc;
}

.icon-express:before {
    content: $icon-express;
}

.icon-click:before {
    content: $icon-click;
}

.icon-hand-shake:before {
    content: $icon-hand-shake;
}

.icon-calendar:before {
    content: $icon-calendar;
}

.icon-hand-euro:before {
    content: $icon-hand-euro;
}

.icon-box:before {
    content: $icon-box;
}

.icon-delivery:before {
    content: $icon-delivery;
}

.icon-file-history:before {
    content: $icon-file-history;
}

.icon-hotline:before {
    content: $icon-hotline;
}

.icon-upload:before {
    content: $icon-upload;
}

.icon-pvc-pipes:before {
    content: $icon-pvc-pipes;
}

.icon-delivery-return:before {
    content: $icon-delivery-return;
}

.icon-hand-screw:before {
    content: $icon-hand-screw;
}

.icon-star:before {
    content: $icon-star;
}

.icon-download:before {
    content: $icon-download;
}

.icon-box-express:before {
    content: $icon-box-express;
}

.icon-mobile-app:before {
    content: $icon-mobile-app;
}

.icon-comptoir-express:before {
    content: $icon-comptoir-express;
}

.icon-hand-file:before {
    content: $icon-hand-file;
}

.icon-tech-doc:before {
    content: $icon-tech-doc;
}

.icon-shopping-cart:before {
    content: $icon-shopping-cart;
}

.icon-air-connect:before {
    content: $icon-air-connect;
}

.icon-glossary:before {
    content: $icon-glossary;
}

.icon-book-download:before {
    content: $icon-book-download;
}

.icon-tools:before {
    content: $icon-tools;
}

.icon-bookmark-file:before {
    content: $icon-bookmark-file;
}

.icon-hammer-file:before {
    content: $icon-hammer-file;
}

.icon-sav:before {
    content: $icon-sav;
}

.icon-video-tuto:before {
    content: $icon-video-tuto;
}

.icon-webinar:before {
    content: $icon-webinar;
}

.icon-e-shop:before {
    content: $icon-e-shop;
}

