/**
 * tile timeline
 */

.tile-timeline {
  $parent: &;
  width: 28rem;
  min-height: 35rem;
  color: var(--bs-dark);
  text-decoration: none;
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    background-color: var(--bs-gray-300);
    bottom: 0;
    z-index: 5;
  }
  &::before {
    width: 1px;
    height: calc(100% - 4em);
    left: 0;
  }
  &::after {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    left: 0;
    transform: translate(-4.5px, 0);
  }
  &__date {
    @include font-size(4rem);
  }
  &__text {
    padding: 1rem 0 0 3em;
  }
  &:hover,
  &:focus {
    color: var(--bs-dark);
    #{$parent}__name {
      text-decoration: underline;
    }
  }
  [class*="cell"] > & {
    height: 100%;
  }
  @include media-breakpoint-up(sm) {

  }
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {
    &::before {
      left: 3.6em;
    }
    &::after {
      left: 3.6em;
    }
    &__text {
      padding-left: 5.6em;
    }
  }
  @include media-breakpoint-up(xl) {
    width: 36rem;

  }
}
