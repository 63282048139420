/**
 *  buttons
 */

.btn {
  position: relative;
  transition: all .2s ease-in-out;
  &__inside {
    position: relative;
    z-index: 5;
    display: inline-block;
  }
  .icon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    fill: currentColor;
    transition: transform .6s ease;
    &--stroke {
      stroke-width: 2px;
      stroke: currentColor;
    }
  }
  &:hover,
  &:focus {
    text-decoration: none;
  }
  p:not([class]) > & {
    margin-bottom: $grid-gutter-width * .5;
  }
  &:not([class*="outline"]) {
    border-width: 0;
    line-height: 1.7435;
  }
  & + .btn {
    margin-left: .8em;
  }
}

.btn-dark,
.btn-outline-dark {
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: linear-gradient(92deg, $gray-900 0%, $purple 100%);
    opacity: 0;
    transform: scale(1.3);
    transition: all .6s ease-in-out;
    pointer-events: none;
  }
  &:hover,
  &:focus {
    color: var(--bs-white);
    &::before {
      transform: scale(1);
      opacity: 1;
    }
    .icon {
      transform: translate(1.4rem, 0);
    }
    .icon--180deg {
      transform: translate(1.4rem, 0) rotate(180deg);
    }
  }
}

.btn-back {
  .icon {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    stroke: var(--bs-body-color);
  }
  &:hover,
  &:focus {
    span {
      text-decoration: none !important;
    }
  }
}


// [class*="btn-outline"] {
//   background-color: var(--bs-white);
// }

.btn-outline-dark {
  background-color: var(--bs-white);
  // &:hover,
  // &:focus {
  //   border-width: 0;
  //   .btn__inside {
  //     padding: 1px;
  //   }
  // }
}

.btn-round {
  .text-white &,
  // .section-home &,
  .section-gradient &,
  .section-gradient-oblique & {
    color: var(--bs-white);
  }
}

.btn-transversal {
  min-height: 11rem;
  padding: 0 ($grid-gutter-width * .5);
  line-height: 1.3;
  text-align: left;
  color: var(--bs-dark);
  transition: all .2s ease-in-out;
  // > span {
  //   z-index: 5;
  // }
  .icon {
    position: absolute;
    top: 50%;
    z-index: 5;
    width: 2.8rem;
    height: 2.8rem;
    transition: all .2s ease-in-out;
    stroke: currentColor;
  }
  &::before {
    content: '';
    display: block;
    width: calc(100% + #{$grid-gutter-width});
    height: 100%;
    background-color: var(--bs-gray-300);
    position: absolute;
    top: 0;
    z-index: 0;
    opacity: 0;
    transition: opacity .2s ease-in-out;
  }
  &:hover,
  &:focus {
    &::before {
      opacity: 1;
    }
  }
  &--prev {
    padding-left: 2.4em;
    &::before {
      right: 0;
      transform: translate(($grid-gutter-width * .5), 0);
    }
    .icon {
      left: 0;
      transform: translate(0, -50%);
    }
    &:hover,
    &:focus {
      .icon {
        transform: translate(-1rem, -50%) scale(1.1);
      }
    }
  }
  &--next {
    padding-right: 2.4em;
    text-align: right;
    &::before {
      left: 0;
      transform: translate((-$grid-gutter-width * .5), 0);
    }
    .icon {
      right: 0;
      transform: translate(0, -50%) scale(-1, 1);
    }
    &:hover,
    &:focus {
      .icon {
        transform: translate(1rem, -50%) scale(-1, 1.1);
      }
    }
  }
  [class*="col"] > & {
    height: 100%;
  }
  @include media-breakpoint-up(sm) {
    &::before {
      width: 100vw;
    }
  }
  @include media-breakpoint-up(md) {
    &::before {
      width: 50vw;
    }
  }
  @include media-breakpoint-up(lg) {
    min-height: 14.1rem;
  }
  @include media-breakpoint-up(xl) {
    &--prev {
      padding-left: 3em;
    }
    &--next {
      padding-right: 3em;
    }
  }
}
