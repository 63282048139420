.field {
    &__radio {
        $self: &;
        position: relative;

        &--disabled {
            #{$self}-label {
                cursor: not-allowed;
            }
        }

        & + #{$self} {
            margin-top: 1em;
        }

        &--inline {
            display: inline-flex;
            margin-right: 8px;

            & + #{$self}--inline {
                margin-top: 0;
            }
        }

        &-container {
            width: 100%;
            margin: -1rem 0;

            &--column {
                display: flex;
                flex-direction: column;

                #{$self} + #{$self} {
                    margin-bottom: .8rem;
                }
            }
        }

        &-label {
            position: relative;
            padding-left: 30px;
            display: inline-flex;

            &:before {
                content: "";
                position: absolute;
                display: block;
                top: 2px;
                left: 0px;
                width: 20px;
                height: 20px;
                background-color: $white;
                border: 1px solid color('primary', 'base');
                border-radius: 50%;
            }

            &-inline {
                padding-left: 0;
                padding-right: 20px;
                margin-bottom: 0;
            }

            &--chip {
                padding: 7px;
                background: $white;
                border-radius: 5px;
                border: 1px solid color('primary', 'base');
                cursor: pointer;
                font-weight: 600;
                color: color('primary', 'base');
                box-shadow: 0 3px 6px rgba($black, 0.16);
                transition: border var(--transition) ease, background var(--transition) ease, color var(--transition) ease;
                &:hover,
                &:focus {
                    background: rgba(#008acd, 0.1);
                    border-color: rgba(#008acd, 0.2);
                    color: color('primary', 'base');
                }

                &:before {
                    display: none;
                }
            }

            sup {
                color: color('danger', 'base');
                top: .5rem;
                left: .3rem;
                position: relative;
            }
        }

        &-input {
            top: 0;
            left: 0;
            width: 100%;
            cursor: inherit;
            margin: 0;
            opacity: 0;
            padding: 0;
            z-index: 1;
            position: absolute;

            &:checked {
                ~ #{$self}-label:before {
                    background: url('../images/valid-checkbox.svg') no-repeat center center, color('primary', 'base');
                }

                ~ #{$self}-label--chip {
                    border-color: color('primary', 'base');
                    background: color('primary', 'base');
                    color: $white;
                }
            }

            &:focus {
                ~ #{$self}-label:before {
                    outline: 2px solid color('primary', 'base');
                }
            }

            &:disabled,
            &.disabled {
                ~ #{$self}-label {
                    color: #8E8E8E;
                }

                ~ #{$self}-label:before {
                    border-color: #8E8E8E;
                    background-color: #8E8E8E;
                }

                &:checked {
                    ~ #{$self}-label:after {
                        background: url('../images/valid-checkbox.svg') no-repeat center center, #8E8E8E;
                        border-color: #8E8E8E;
                    }
                }
            }
        }
    }
}
