/**
 * dropdown
 */

.dropdown {
  .button {
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.5;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    border: none;
    background-color: transparent;
    transition: all .2s ease-in-out;
    touch-action: manipulation;
    &:hover,
    &:focus {
      text-decoration: none;
      color: #3692fc;
      background-color: rgba(0,0,0,.05);
      border-color: transparent;
    }
  }
  &-toggle {
    position: relative;
  }
  &-block {
    display: none !important;
    background-color: white;
    box-shadow: 0 1px 8px rgba(black, 0.3);
    max-width: 30rem !important;
    @include media-breakpoint-up(lg) {
      max-width: 50rem !important;
    }
    position: absolute !important;
    bottom: -1rem;
    right: 0;
    z-index: 9999 !important;
    transform: translateY(100%);
    > * {
      padding: .5rem 1rem;
      display: block;
      color: $body-color;
      text-decoration: none;
    }
    a {
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
  &-on > .dropdown-block {
    display: block !important;
  }
}
