/**
 * tile trombi
 */

.tile-trombi {
  $parent: &;
  max-width: 22rem;
  margin: 0 auto ($grid-gutter-width * .5);
  border-width: 0;
  padding: 0 0 1.6em;
  background-color: transparent;
  color: var(--bs-dark);
  text-decoration: none;
  &__pic {
    width: 100%;
    aspect-ratio: 1/1;
    margin: 0 0 1.6em;
    img {
      object-fit: cover;
    }
  }
  &__name {

  }
  &__fx {

  }
  [class*="cell"] > & {
    margin-right: auto;
    margin-left: auto;
  }

}

a.tile-trombi,
button.tile-trombi {
  &::before,
  &::after {
    content: '';
    display: block;
    width: 1px;
    height: 1.7rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 5;
    background-color: var(--bs-dark);
  }
  &::before {
    transform: translate(-50%, 0);
  }
  &::after {
    transform: translate(-50%, 0) rotate(90deg);
  }
  &:hover,
  &:focus {
    color: var(--bs-dark);
    .tile-trombi__name {
      text-decoration: underline;
    }
  }
}
