.form-error {
    margin-bottom: 1em;

    &--message.form-error {
        background: $white;
        border: 2px solid #d61036;
        border-radius: 4px;
        padding: 20px;
        margin-bottom: 20px;

        .form-error__content {
            display: block;
            margin-top: 0;
            text-align: center;
        }
    }

    &__icon {
        display: inline-block;
        vertical-align: bottom;
        padding-right: 10px;
        font-size: 15px;
        color: #d61036;
    }

    .form-error__content {
        display: inline-block;
    }

    &__content {
        display: none;
        margin-top: 10px;
        padding: 5px;
        border-radius: 5px;
        font-size: 12px;
        line-height: 1.2;
        font-weight: 700;
        letter-spacing: 0.1px;
        color: #d61036;
        background: #F7DADA;
    }

    &__list {
        margin-left: 40px;
        margin-top: 0;
        font-weight: 700;
        color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));
    }

    .field__input,
    .field__select,
    .field__textarea {
        background-color: #F7DADA;

        &::placeholder {
            color: #d61036;
        }
    }

    .field__checkbox-label:before,
    .field__radio-label:before {
        border: 2px solid #d61036;
    }
}

.form-success {
    .field__input,
    .field__textarea {
        background: url("../images/valid.png") no-repeat, #F7DADA;
        background-position: calc(100% - 21px) center;
        background-size: 24px 24px;
    }
    .field__select {
        background: #F7DADA;
    }
}
