//
// META
// --------------------------------------------------

html {
  font-size: 62.5%; // font-size: 50%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: scrollbar; // http://stackoverflow.com/questions/16831751/ie10-stop-scroll-bar-from-appearing-over-content-and-disappearing
  // @include media-breakpoint-up(sm) {
  //   font-size: 62.5%; // font-size: 61%;
  // }
  // @include media-breakpoint-up(md) {
  //   font-size: 62.5%; // font-size: 62%;
  // }
  // @include media-breakpoint-up(lg) {
  //   font-size: 62.5%; // font-size: 62.5%;
  // }
}

@media (-moz-device-pixel-ratio: 1.25) {
  html {
    // zoom: 0.8;
    font-size: 50%;
  }
}
@media (-moz-device-pixel-ratio: 1.5) {
  html {
    // zoom: 0.7;
    font-size: 44%;
  }
}
@media (-webkit-device-pixel-ratio: 1.25) {
  html {
    // zoom: 0.8;
    font-size: 50%;
  }
}
@media (-webkit-device-pixel-ratio: 1.5) {
  html {
    // zoom: 0.7;
    font-size: 44%;
  }
}


body {
  // padding-bottom: 46px; // Sticky Ad
  // &.home{
  //   padding-bottom: 0;  // Sticky Ad - hidden on homepage (modal instead)
  // }
  @include media-breakpoint-up(md) {
    *::-webkit-scroll {
      width: 10px;
      height: 10px;
    }
    *::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    *::-webkit-scrollbar-corner {
      background: transparent;
    }
    *::-webkit-scrollbar-thumb {
      background: #FFF;
      border-radius: 16px;
    }
    *::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 16px;
    }
  }
}

::selection {
  background: var(--bs-primary);
  color: #FFF;
}
::-moz-selection {
  background: var(--bs-primary);
  color: #FFF;
}

// .visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
//   position: absolute;
//   display: none !important;
// }

[data-card-link] {
  cursor: pointer;
}

// .icon-round {
//   width: 5rem;
//   height: 5rem;
//   padding: 0 .625em;
//   // box-shadow: 0 0 10px 2px rgba(0, 0, 0, .1);
//   .icon {
//     @extend %icon
//   }
// }
