/**
 * sticky header
 */

body .page {
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    // padding-top: 146px;
    .no-intro & {
      padding-top: 110px;
    }
  }
}

.wrapper-sticky {
  position: relative;
  z-index: 2100;
  height: 59px;
  transition: height .2s ease-in-out;
  //@include media-breakpoint-up(md) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  //}
  .header-sticky--active.header-sticky--down & {
    box-shadow: 0 0 10px rgba($dark, .2);
  }
  @include media-breakpoint-up(lg) {
    height: 110px;
    // .header-sticky--active & {
    //   height: 110px;
    // }
    .header-sticky--active.header-sticky--down & {
      height: 110px;
    }
  }
}
