.field {
    &__textarea {
        $self: &;
        width: 100%;
        height: 100%;
        min-height: 175px;
        padding: 28px 25px;
        background-color: $white;
        border: 1px solid color('primary', 'base');
        border-radius: 6px;
        color: color('text', 'base');
        resize: none;
        transition: transform .3s;
        box-shadow: inset 0 3px 6px rgba($black, 0.16);

        &:focus {
            outline: none;
            border-color: color('primary', 'dark');
        }

        &::placeholder {
            font-size: 20px;
            line-height: 1.25;
            font-weight: 700;
            color: #B5B5B5;
        }
    }
}
