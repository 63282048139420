/**
 * Quintilien
 */

.quintilien {
  max-width: 440px;
  margin: 0 auto $grid-gutter-width;
  &__list {
    dt {
      margin-bottom: .15em;
      font-family: $font-family-display;
      font-size: 2rem;
    }
    dd {
      margin-bottom: .8em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include media-breakpoint-up(sm) {

  }
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
    margin-left: 0;
    &__list {
      dt {
        font-size: 2.2rem;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &__inner {
      padding: 1rem 1.8rem;
    }
    &__list {
      dt {
        margin-bottom: .3em;
        font-size: 2.6rem;
      }
      dd {
        margin-bottom: 2em;
      }
    }
  }
  @include media-breakpoint-up(xl) {

  }
}
