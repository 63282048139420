.field {
    &__select {
        position: relative;
        display: block;
        width: 100%;
        box-shadow: 0 3px 6px rgba($black, 0.16);
        border: 1px solid color('primary', 'base');
        border-radius: 6px;
        background: color('background', 'bg') ;
        padding: 15px 65px 10px 15px;
        color: $black;
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;
        overflow: hidden;
        transition: color, background 0.3s ease;
        background: url("../images/arrow.png") no-repeat;
        background-position: right  top;
        background-size: 50px 100%;
        appearance: none;

        &:focus {
            color: $white;
            background-color: color('primary', 'base');
        }
    }
}
