$primary: (
    "base": #008acd,
    "light": #764896,
    "dark": #d61036,
) !default;

$secondary: (
    "base": #F7A600,
    "light": #FAB600,
) !default;

$background: (
    "base": #F8F9FC,
    "blue": #058FBE,
    "grey": #F7F7F7,
    "bg": #FAFAFA,
) !default;

$text: (
    "base": #121212,
) !default;

$grey: (
    "base": #EFEFEF,
    "dark": #627A9A,
    "light": #333242,
    "medium": #333242,
) !default;

// Semantic colors
$success: (
    "base": #96C133,
    "light": #FCFFF5,
) !default;

$warning: (
    "base": #FE7D0D,
) !default;

$danger: (
    "base": #d61036,
    "light": #F7DADA,
) !default;


$dashboard: (
    "base": #BE3F3F,
    "green": #25C02C,
    "yellow": #FED70D,
) !default;


$colors: (
    "primary": $primary,
    "secondary": $secondary,
    "background": $background,
    "grey": $grey,
    "text": $text,
    "success": $success,
    "warning": $warning,
    "danger": $danger,
    "dashboard": $dashboard,
) !default;

$black: #000000;
$white: #ffffff;

$box-shadow: 0 2px 3px rgba($black, 0.16);