.field {
    &__checkbox {
        $self: &;
        position: relative;

        & + #{$self} {
            margin-top: 8px;
        }

        &--disabled {
            #{$self}-label {
                cursor: not-allowed;
            }
        }

        &-label {
            position: relative;
            display: inline-block;
            padding-left: 50px;

            &:before {
                content: "";
                position: absolute;
                display: block;
                top: 50%;
                left: 2px;
                transform: translateY(-50%);
                width: 21px;
                height: 21px;
                background-color: white;
                border: 1px solid #DDDDDD;
                border-radius: 50%;
                box-shadow: inset 0px 3px 6px rgba(black, 0.16);
                cursor: pointer;
            }
        }

        &-input {
            top: 0;
            left: 0;
            width: 100%;
            cursor: inherit;
            margin: 0;
            opacity: 0;
            padding: 0;
            z-index: 1;
            position: absolute;

            &:checked {
                ~ #{$self}-label:before {
                    background: linear-gradient(270deg,#008acd 0,#764896 50.5%,#d61036 100%);
                    border-color: linear-gradient(270deg,#008acd 0,#764896 50.5%,#d61036 100%);
                    box-shadow: none;
                }
            }

            &:focus {
                ~ #{$self}-label:before {
                    outline: 2px solid rgba(#764896, 0.3);
                }
            }

            &:disabled,
            &.disabled {
                ~ #{$self}-label:before {
                    border-color: #8E8E8E;
                    cursor: not-allowed;
                }

                &:checked {
                    ~ #{$self}-label:before {
                        cursor: not-allowed;
                        background: rgba(#764896, 0.3);
                        border-color: rgba(#764896, 0.3);
                    }
                }
            }
        }
    }
}
