.field {

    //&-group,
    //&__group {
    //    position: relative;
    //    display: inline-flex;
    //    width: 100%;
    //
    //    &__adornment {
    //        display: flex;
    //        font-size: 1em;
    //        // margin-left: .8rem;
    //        color: inherit;
    //        white-space: nowrap;
    //        flex-shrink: 0;
    //    }
    //}

    &__row {
        & + .field__row {
            margin-top: 1em;
        }

        &--inline {
            display: flex;

            > *[class^="field__"] + *[class^="field__"] {
                margin-left: .5rem * 2;
                margin-top: 0;
            }
        }
    }
}
