.field {
    &__feedback {
        font-size: 1.2rem;
        line-height: 1.6rem;
        min-height: 3rem;
        padding: .6rem 0 .8rem .8rem;
        visibility: hidden;

        &.error {
            color: color('danger', 'base');
            visibility: visible;

            ul {
                margin: 0;
                padding: 0;
                padding-left: 1em;
            }
        }
    }
}
