.fieldset {
    padding: 25px 0;
    background: $white;
    border: 1px solid color('primary', 'base');

    &--no-border {
        border: 0;
    }

    @include media-breakpoint-up('medium') {
        padding: 50px 0 ;
    }

    .legend,
    &__title {
        @extend h3;
        margin-bottom: 30px;

        @include media-breakpoint-up('medium') {
            margin-bottom: 40px;
        }
    }
}
