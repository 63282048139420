/**
 * form
 */

// .form-switch {
//   line-height: 1.6;
// }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

input,
textarea {
  @include media-breakpoint-down(md) {
    .ios &,
    .safari & {
      font-size: 1.61rem !important; // https://github.com/twbs/bootstrap/issues/2159
    }
  }
}

.form-control {
  &::-webkit-file-upload-button {
    background: var(--bs-primary);
    color: var(--bs-white);
    @extend .rounded-pill;

  }
  &:hover,
  &:focus {
    &::-webkit-file-upload-button {
      background: var(--bs-secondary) !important;
    }
  }
  &:disabled {
    color: var(--bs-dark);
  }
}

// fieldset {
//   padding-bottom: 2em;
//   &:last-child {
//     margin-bottom: 0 !important;
//     padding-bottom: 0;
//   }
// }

// filter with hidde ncheckbox or radio
.btn-filter-toggle {
  color: var(--bs-white);
  border-color: var(--bs-white);
  background-color: transparent;
  
  // Au survol
  &:hover, &:focus {
      color: var(--bs-white);
      background-color: var(--bs-dark);
      border-color: var(--bs-dark) !important;
  }
  
  // classe active
  &.active {
      border-color: var(--bs-dark) !important;
      background-color: var(--bs-dark);
      color: var(--bs-white);
  }
}


.form-validation {
  position: relative;
}

// .form-control {
//   &::placeholder {
//     font-weight: 400;
//     color: var(--bs-dark) !important;
//     .form-floating > & {
//       opacity: 0;
//     }
//   }
// }

.pristine-error {
  @extend .fs-12;
  color: var(--bs-red);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  transform: translateY(110%);
  [class*="col-"] > & {
    padding-left: 2.2rem;
  }
  .g-3 .col > & {
    padding-left: .6rem;
  }
}
.has-danger {
  input,
  select,
  textarea {
    border: 1px solid var(--bs-red);
  }
}

.custom-date-field {
  // input[type="date"]:not(.has-value):before {
  //   color: currentColor;
  //   content: attr(placeholder);
  // }
  ::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer;
  }
  &-icon {
    top: 50%;
    right: 1.8rem;
    z-index: 15;
    width: 30px;
    height: 30px;
    padding: 2px;
    transform: translate(0, -50%);
    background-color: var(--bs-white);
    *:disabled + & {
      background-color: var(--bs-gray-100);
    }
    .icon {
      display: block;
      width: 100%;
      height: 100%;
      fill: currentColor;
    }
  }
}
