/**
 * anchors nav
 */

.sticky-playground {
  // padding-top: 60px;
  // > *:first-child:not(.section-anchors) {
  //   margin-top: 1.5em;
  // }
  // @include media-breakpoint-up(lg) {
  //   padding-top: 90px;
  // }
}

.section-anchors,
.section-filters {
  height: 60px;
  z-index: 250;
  transition: height .2s ease-in-out;
  @extend %linearGradient1;
  &.js-is-sticky {
    position: fixed !important;
    width: 100%;
    transform: translate(0, 0);
    border-top: 1px solid var(--bs-dark);
    border-bottom: 1px solid var(--bs-dark);
  }
  @include media-breakpoint-down(lg) {
    &.js-is-sticky {
      transform: translate(0, -51.5px)
    }
  }
  @include media-breakpoint-up(lg) {
    @media screen and (-webkit-device-pixel-ratio: 1) {
      height: 70px;
    }
    // transform: translate(0, -90px);
  }
}


.toc-select {
  background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m2 5 6 6 6-6" /></svg>');
}

.toc {
  &__list {
    @include resetUl;
  }
  &__item {
    flex: 0 0 auto;
  }
  &__link {
    text-decoration: none;
    line-height: 1.5625;
    color: var(--bs-white);
    &::after {
      content: '';
      display: block;
      width: 0;
      height: 4px;
      background-color: var(--bs-dark);
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 5;
      transform: translate(-50%, 0);
      transition: all .2s ease-in-out;
    }
    &.active {

    }
    &.active,
    &:hover,
    &:focus {
      &::after {
        width: 100%;
      }
    }
  }
}

.section-filters {

}
