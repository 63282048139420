/**
 * tile testimny
 */

.tile-testimony {
  $parent: &;
  margin: 0 0 $grid-gutter-width;
  color: var(--bs-dark);
  &__inner {

  }
  &__pic {
    aspect-ratio: 3.55/2;
    img {
      object-fit: cover;
    }
  }
  &__limit {
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    overflow: hidden;
  }
  &__title {
    color: var(--bs-gray-600);
  }
  &__excerpt {

  }

  &__date {

  }
  &__arrow {

  }
  [class*="cell"] > & {
    margin-right: auto;
    margin-left: auto;
  }
  // [class*="col"] > & {
  //   height: calc(100% - #{$grid-gutter-width});
  // }

  &:hover,
  &:focus {

  }
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {

  }
  @include media-breakpoint-up(xl) {

  }
}
