/**
 * tile pilier
 */

.tile-pillar {
  $parent: &;
  height: 32rem;
  margin: 0 0 $grid-gutter-width;
  &__inner {
    padding: ($grid-gutter-width * .5);
  }
  &__pic {
    img {
      object-position: 50% 0;
      object-fit: cover;
      transition: all .6s ease;
    }
  }
  &__title {
    // padding: 0 0 33%;
    // transition: all .2s ease;
  }
  &__excerpt {
    max-height: 4.4em;
    height: auto;
    overflow: hidden;
    opacity: 0;
    transform: translate(0, 2rem);
    transition: all .7s ease;
  }
  &__limit {
    overflow: hidden;
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    overflow: hidden;
  }
  [class*="cell"] > & {
    margin-right: auto;
    margin-left: auto;
  }
  // [class*="col"] > & {
  //   height: calc(100% - #{$grid-gutter-width});
  // }
  &--tiny {
    width: 100%;
    max-width: 22rem;
    margin: 0 auto $grid-gutter-width;
    height: auto !important;
    aspect-ratio: 2/1;
  }
  &:hover,
  &:focus {
    #{$parent}__pic {
      > img {
        object-position: 50% 100%;
      }
    }
    #{$parent}__title {
      // padding-bottom: 0;
    }
    #{$parent}__excerpt {
      max-height: 100%;
      opacity: 1;
      transform: none;
      // transition: all .7s ease;
    }
  }
  @include media-breakpoint-up(sm) {
    &--tiny {
      max-width: 32rem;
    }
  }
  @include media-breakpoint-up(lg) {
    &__inner {
      padding: ($grid-gutter-width * .75);
    }
    &--tiny {
      margin-bottom: 0 !important;
    }
    &:hover,
    &:focus {
      #{$parent}__excerpt {
        padding-bottom: 2rem;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    height: 42rem;
  }
}
