.spacing {
    display: block;
    width: 100%;

    &--preview {
        position: relative;
        padding: 10px;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            border: 1px dashed black;
            transform: translate(-50%, -50%);
        }
    }

    &__small {
        height: 30px;
    }

    &__medium {
        height: 60px;
    }

    &__large {
        height: 120px;
    }
}
