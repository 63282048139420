/**
 * BREAKPOINTS for js
 */

body::after {
  content: 'xs';
  position: absolute;
  bottom: 0;
  left: -2em;
  visibility: hidden;
  font-size: 1px;
  @include media-breakpoint-only(xs) {
    content: 'xs';
  }
  @include media-breakpoint-up(sm) {
    content: 'sm';
  }
  @include media-breakpoint-up(md) {
    content: 'md';
  }
  @include media-breakpoint-up(lg) {
    content: 'lg';
  }
  @include media-breakpoint-up(xl) {
    content: 'xl';
  }
  @include media-breakpoint-up(xxl) {
    content: 'xxl';
  }
}
