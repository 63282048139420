/**
 * titles utilities
 */

body {
  @media screen and (max-width: 992px) {
    overflow: scroll !important;  
  }

  .section-home-mobile {
      background: black;

      @media screen and (min-width: 992px) {
        background: transparent;  
      }
  }
}

.title-of-section {
  @extend .position-relative;
  @extend .text-center;
  @extend .fw-extrabold;
  @extend .mb-5;
  @include font-size(5rem);
  @extend .text-primary;
  padding-bottom: 7.25rem;
  &::after {
    content: '';
    display: block;
    width: 1px;
    height: 5rem;
    background-color: var(--bs-gray-600);
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 5;
    transform: translate(-50%, 0);
  }
  .section-gradient &,
  .section-gradient-oblique &,
  .text-white & {
    @extend .text-white;
    &::after {
      background-color: currentColor;
    }
  }
}

.h3-display {
  font-family: $font-family-display;
  @extend .fw-normal;
  @extend .text-primary;
  @include font-size(2.6rem);
  .section-gradient &,
  .section-gradient-oblique &,
  .text-white & {
    @extend .text-white;
  }
}

.h4-display {
  margin: 1em 0 1em;
  @extend .fw-extrabold;
  @include font-size(2rem);
  line-height: 1.55;
}

//.edito {
// [class^="col"]  {
//   h1:not([class]),
//   h2:not([class]),
//   h3:not([class]),
//   h4:not([class]),
//   h5:not([class]),
//   h6:not([class]) {
//     margin-top: 0;
//     padding-top: 0;
//   }
// }
h1,
.h1 {
  margin: .925em 0 .46em;
  font-weight: 800;
  transition: all .6s ease;
  .pageload & {
    opacity: 0;
    transform: translate(0, -10%);
  }
  opacity: 1;
  transform: translate(0, 0);
}
h2:not([class]),
.h2 {
  color: var(--bs-primary);
  font-weight: 900;
  // margin-bottom: .7em;
  // font-weight: 700;
  // line-height: 1.4;
  // &:last-child {
  //   margin-bottom: .8em;
  // }
}
h3:not([class]),
.h3 {
  margin-top: 2em;
  color: var(--bs-primary);
}
h2, h3, h4, h5, h6,
.h2, .h3, .h4, .h5, .h6 {
  position: relative;
  z-index: 50;
  *:not([class*="float"]) + & {
    margin-top: 1.8em;
  }
  [class*="float-end"] + & {
    margin-top: 0;
  }
  // .framed + & {
  //   margin-top: 1.6em;
  // }
  &:first-child {
    margin-top: 0;
  }
  .section-gradient &,
  .text-white & {
    @extend .text-white;
  }
  // .framed & {
  //   color: var(--bs-dark);
  //   margin-left: 0 !important;
  // }
}
// h2:not([class]), h3:not([class]),
// .h2, .h3 {

// }

// h3 {
//   margin-bottom: 1em;
// }

// .first-line-italic {
//   &::first-line {
//     font-style: italic;
//   }
// }

// .first-line-indent {
//   text-indent: 1em;
// }

