.field {
    $self: &;
    display: flex;
    flex-direction: column;
    width: 100%;

    &--inline {
        display: inline-flex;
        width: auto;
        flex-wrap: wrap;

        & + & {
            margin-left: .5rem * 2;
        }
    }

    &--hide-feedback {
        #{$self}__feedback {
            display: none;
        }
    }

    & + & {
        margin-top: 4rem;
    }

    /* Change the white to any color ;) */
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
}
