/**
 * breadcrumb
 */

.breadcrumb-wrapper {
  z-index: 5;
  transition: all .8s ease;
  .pageload & {
    opacity: 0;
    transform: translate(0, -15%);
  }
  opacity: 1;
  transform: translate(0, 0);
}

.breadcrumb {
  $parent: &;
  margin: 0 0 2.275em;
  a {
    font-weight: 400;
    color: var(--bs-dark);
  }
  .text-center & {
    justify-content: center;
  }
  .section-gradient &,
  .section-gradient-oblique &,
  .text-white & {
    color: var(--bs-white);
    a {
      color: var(--bs-white);
    }
    &-item {
      &.active {
        color: var(--bs-white);
      }
      + #{$parent}-item {
        &::before {
          color: var(--bs-white);
        }
      }
    }
  }
}

// .breadcrumb {
//   &-item {
//     position: relative;
//     &::before {
//       display: block;
//       float: none !important;
//       width: .6rem;
//       height: .6rem;
//       border-radius: 100%;
//       padding: 0 !important;
//       background-color: var(--bs-yellow);
//       position: absolute;
//       top: 50%;
//       left: 1em;
//       z-index: 5;
//       transform: translate(0, -50%);
//     }
//     a {
//       color: var(--bs-gray-900);
//       text-decoration: underline;
//       &:hover,
//       &:focus {
//         color: #000;
//         text-decoration: none;
//       }
//     }
//     &:first-child {
//       padding-left: 0;
//     }
//   }
//   @include media-breakpoint-up(xl) {
//     // padding-left: 1em;
//   }
//   @include media-breakpoint-up(xxl) {
//     // padding-left: 2.8em;
//   }
//   .main-banner--narrow & {
//     margin-bottom: 0;
//   }
// }


