/**
 *  overlay
 */

.global-overlay {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  transition: all .5s cubic-bezier(0, .5, .5, 1);
  overflow-y: hidden;
  top: 0;
  right: 0;
  z-index: 500;
  width: 100vw;
  height: 100vh; //height: calc(100vh - 82px);
  background: linear-gradient(230deg, #008ACD 0%, #764896 50.50%, #D61036 100%);
  outline: none !important;
  &.active {
    visibility: visible;
    opacity: 1;
  }
  @include media-breakpoint-up(xl) {
    display: none;
  }
}
