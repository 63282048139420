.field {
    &__input-group {
        position: relative;

        .icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__input {
        $self: &;

        width: 100%;
        background-color: transparent;
        padding: 15px 60px 15px 23px;
        border-radius: 6px;
        border: 1px solid color('primary', 'base');
        font-size: 20px;
        line-height: 1.25;
        font-weight: 700;
        color: color('text', 'base');
        transition: border 0.2s ease, padding 0.2s ease;
        box-shadow: inset 0 3px 6px rgba($black, 0.16);

        &:focus {
            outline: none;
            border-color: color('primary', 'dark');
        }

        &::placeholder {
            font-size: 20px;
            line-height: 1.25;
            font-weight: 700;
            color: #B5B5B5;
        }
    }
}
