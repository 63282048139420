/**
 * edito
 */

// [class*="col"] > {
//   p, ul, ol {
//     &:last-child {
//       margin-bottom: 0 !important;
//     }
//   }
// }

.motto {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  margin-top: 1.4em;
  font-family: $font-family-display;
  color: var(--bs-gray-600);
  text-align: center;
  @include font-size(4rem);
  .text-white & {
    color: var(--bs-white);
  }
  &--smaller {
    padding-top: .4em;
    padding-bottom: .4em;
    @include font-size(2.6rem);
  }
}

.blockquote-wrapper {
  $parent: &;
  max-width: 840px;
  margin-top: 3em;
  margin-bottom: 3.6em;
  background-color: var(--bs-dark);
  color: var(--bs-white);
  border-radius: $border-radius;
  padding: ($grid-gutter-width * 1) 4.6em ($grid-gutter-width * 1) 3.8em;
  &__icon {
    &-start,
    &-end {
      fill: currentColor;
      .icon {
        @extend %icon;
      }
    }
    &-start {
      width: 3.2rem;
      height: 3.2rem;
      transform: translate(35%, 115%);
      color: var(--bs-gray-600);
    }
    &-end {
      width: 6.3rem;
      height: 6.3rem;
      transform: translate(-20%, -20%) rotate(180deg);
      color: var(--bs-primary);
    }
  }
  &--gradient {
    @extend %linearGradient2;
    margin-right: auto;
    margin-left: auto;
    .blockquote {
      font-family: $font-family-base;
      font-weight: 700;
      font-size: $font-size-base;
      p {
        line-height: 1.65;
      }
    }
    #{$parent}__icon {
      &-start,
      &-end {
        width: 2.4rem;
        height: 2.4rem;
        transform: translate(-20%, -20%);
        color: currentColor;
      }
      &-start {
        transform: translate(100%, 100%);
      }
      &-end {
        transform: translate(-100%, -100%);
      }
    }
  }
  @include media-breakpoint-up(sm) {

  }
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {
    padding-top: ($grid-gutter-width * 1.2);
    padding-right: 6em;
    padding-left: 6.8em;
    &__icon {
      &-start {
        transform: translate(170%, 155%);
      }
      &-end {
        transform: translate(-110%, -55%) rotate(180deg);
      }
    }
    &--gradient {
      margin-top: 0;
      padding: 5.1em 4.6em 7.5em 4.3em;
      #{$parent}__icon {
        &-start {
          transform: translate(170%, 205%);
        }
        &-end {
          transform: translate(-200%, -255%);
        }
      }
    }
  }
}
.blockquote {
  max-width: 80rem;
  margin: 0 auto;
  font-family: $font-family-display;
  text-align: start;
  p {
    line-height: 1.52;
  }
  &-footer {
    margin: 0 !important;
    padding: .3rem 0 0;
    &::before {
      display: none !important;
    }
    color: var(--bs-gray-600);
  }
}

.flex-icon {
  flex: 0 0 2rem;
  .icon {
    width: 2.1rem;
    height: 2.1rem;
    stroke: currentColor;
  }
}

.h-screen {
  height: 100vh;
}

.font-family-display {
  font-family: $font-family-display;
}
.fw-medium {
  font-weight: 500;
}
.fw-extrabold {
  font-weight: 800;
}
.fs-base {
  font-size: $font-size-base !important; // 16px
}
.fs-0 {
  @include font-size(5rem);
}
.fs-7 {
  @include font-size(1.5rem);
}
.fs-8 {
  @include font-size(1.4rem);
}
.fs-9 {
  @include font-size(1.3rem);
}
.fs-10 {
  font-size: 1.2rem !important;
}
.fs-11 {
  font-size: 1.1rem !important;
}
.fs-12 {
  font-size: 1rem !important;
}

.lh-xs {
  line-height: 1 !important;
}


.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}

iframe {
  border: none;
}

.noscroll,
.no-scroll {
  overflow: hidden;
}

.chapo {
  max-width: 86rem;
  @include font-size(2rem);
  @extend .mx-auto;
  transition: all .7s ease;
  .pageload & {
    opacity: 0;
    transform: translate(0, -15%);
  }
  opacity: 1;
  transform: translate(0, 0);
  .main-intro__chapo &,
  .main-intro__footer & {
    max-width: initial;
    margin-right: 0;
    margin-left: 0;
  }
}
