/**
 * MAIN FOOTER
 */

.main-footer {

  // padding-bottom: calc(44vh - 2.625rem);
  @include media-breakpoint-up(md) {
    padding-bottom: var(--footerHeight);
    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
    }
  }

}

.footer-sitemap {
  @include resetUl;
  padding-top: .25em;
  padding-bottom: .4em;
  a {
    padding-top: .15em;
    padding-bottom: .15em;
    color: var(--bs-white);
    background-image: linear-gradient(transparent calc(100% - 1px), var(--bs-white) 1px);
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: 0 100%;
    background-size: 0 100%;
    transition: all .2s ease-in-out;
    &:hover,
    &:focus {
      background-size: 100% 100%;
      color: var(--bs-white);
    }
  }
}

.border-top-footer {
  border-top: 1px solid var(--bs-gray-600);
}

.line-footer {
  @include media-breakpoint-down(lg) {
    padding-bottom: 8rem !important;
  }
  // @include media-breakpoint-up(xxl) {
  //   padding-right: 10em;
  //   padding-left: 10em;
  // }
}

.footer-airvance {
  @include media-breakpoint-up(md) {
    &::before,
    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 1rem;
      background-color: var(--bs-white);
      opacity: .5;
      position: absolute;
      top: 50%;
      z-index: 5;
      transform: translate(0, -50%);
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
}

.footer-address {
  @include media-breakpoint-up(md) {
    letter-spacing: .02em;
  }
}

.footer-rs,
.footer-airvance,
.footer-address {
  @include media-breakpoint-up(md) {
    padding-right: 1em;
  }
  @include media-breakpoint-up(lg) {
    padding-right: 1.5em;
  }
  @include media-breakpoint-up(xl) {
    padding-right: 2.5em;
  }
}
.footer-airvance,
.footer-address {
  @include media-breakpoint-up(md) {
    padding-left: 1em;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 1.5em;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 2.5em;
  }
}
