// skip links
.skip {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2500;
  font-size: 1.4rem;
}
.skip-list {
  margin: 0 auto;
  padding: 0;
  list-style: none;
}
.skip-list__item {

}
.skip-list__link {
  @include visually-hidden-focusable;
  padding: .5em 1em;
  &:hover,
  &:focus {
    background: var(--bs-primary);
    color: #FFF;
  }
}
