/**
 * main intro
 */

// .free-background__bg {
//   height: 200%;
// }

.free-background,
.main-intro {
  &__bg {
    z-index: -1;
    background: linear-gradient(225deg, $blue 0%, #764896 50.50%, #D61036 100%);
    img {
      position: relative;
      z-index: -1;
      // mix-blend-mode: lighten;
      // backdrop-filter: blur(7.5px);
      object-fit: cover;
      transform: scale(1.1);
      // transition: all .6s ease;
    }
    + * {
      z-index: 5;
    }
  }
  // &__layer1 {
  //   opacity: .5;
  //   background: linear-gradient(225deg, $blue 0%, #764896 50.50%, #D61036 100%);
  //   backdrop-filter: blur(7.5px);
  //   // backdrop-filter: invert(80%);
  //   z-index: 0;
  // }
  // &__layer2 {
  //   opacity: .8;
  //   background: linear-gradient(180deg, #25272F 0%, rgba(37, 39, 47, 0.00) 100%);
  //   // mix-blend-mode: multiply;
  //   z-index: 1;
  //   display: none;
  // }
  &__layer1 {
    z-index: 0;
    background: var(--gradient, linear-gradient(225deg, $blue 0%, #764896 50.50%, #D61036 100%));
    mix-blend-mode: lighten;
    // backdrop-filter: blur(7.5px);
  }
  &__layer2 {
    z-index: 1;
    opacity: 0.5;
    background: var(--gradient, linear-gradient(225deg, $blue 0%, #764896 50.50%, #D61036 100%));
    // backdrop-filter: blur(7.5px);
  }
  &__layer3 {
    z-index: 2;
    opacity: .6;
    background: linear-gradient(180deg, $gray-900 0%, rgba(37, 39, 47, 0.00) 100%);
    mix-blend-mode: multiply;
  }
}

.main-intro {
  $parent: &;
  overflow: hidden;
  height: 678px;
  max-height: calc(100vh - 60px);
  padding: 89px 0 0;
  @extend %linearGradient1;
  &__inner {
    z-index: 5;
  }
  &__hr {
    bottom: 10%;
    left: 0;
    z-index: 5;
    transition: opacity .2s ease;
    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 5rem;
      background-color: var(--bs-white);
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 5;
      transform: translate(-50%, 0);
      transition: all .6s ease;
    }
    hr {
      opacity: 0;
    }
    .header-sticky--active & {
      opacity: 0;
    }
  }
  &__tags,
  &__date {
    transition: all .5s ease-in-out;
    transform: translate(0, 0);
  }
  &__full-width-pic {
    aspect-ratio: 1440/600;
    max-height: 60rem;
    img {
      object-fit: cover;
    }
  }
  .pageload & {
    #{$parent}__tags,
    #{$parent}__date {
      opacity: 0;
      transform: translate(0, -10%);
    }
    #{$parent}__bg {
      img {
        transform: scale(1);
      }
    }
  }
  &--freeHeight {
    height: auto;
    max-height: initial;
    padding-bottom: $grid-gutter-width;
  }
  &--article,
  &--realisation {
    height: auto;
    max-height: inherit;
  }
  &--article {
    #{$parent}__pic {
      aspect-ratio: 1.10868/1;
      > img {
        object-fit: cover;
      }
    }
    #{$parent}__footer {
      margin-top: 2em;
      padding: 1.8em 0 .4em;
      &::before {
        content: '';
        display: block;
        width: 100vw;
        height: 100%;
        background-color: var(--bs-dark);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        margin-left: (-$grid-gutter-width * .5);
      }
    }
  }
  &--realisation {
    #{$parent}__title {
      margin-top: .5em;
    }
  }
  @include media-breakpoint-only(xs) {
    &--freeHeight {
      #{$parent}__hr {
        display: none;
      }
    }
  }
  @include media-breakpoint-up(sm) {
    // padding-top: 89px;
    &--freeHeight {
      padding-bottom: 13.8rem;
    }
    &--article {
      #{$parent}__pic {

      }
      #{$parent}__footer {
        &::before {
          margin-left: calc((-100vw + #{$width-sm}) * .5 - (#{$grid-gutter-width} * .5));
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    &--article {
      #{$parent}__pic {
        max-width: 25rem;
        margin: 1em auto 1em;
      }
    }
  }
  @include media-breakpoint-up(md) {
    padding-top: 109px;
    &--freeHeight {
      padding-bottom: 13.8rem;
    }
    &--article {
      #{$parent}__pic {
        margin-top: 2em;
        margin-bottom: 0;
      }
      #{$parent}__title {
        font-size: 3rem;
      }
      #{$parent}__footer {
        &::before {
          margin-left: calc((-100vw + #{$width-md}) * .5 - (#{$grid-gutter-width} * .5));
        }
      }
    }
    &--realisation {
      .quintilien {
        width: calc(100% - #{$grid-gutter-width});
        position: absolute;
        top: 0;
        right: ($grid-gutter-width * .5);
        z-index: 10;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    padding-top: 139px;
    &--article {
      #{$parent}__pic {
        margin-top: 2.6em;
      }
      #{$parent}__title {
        font-size: 4rem;
      }
      #{$parent}__footer {

        &::before {
          margin-left: calc((-100vw + #{$width-lg}) * .5 - (#{$grid-gutter-width} * .5));
        }
      }
    }
    @media screen and (max-height: 700px) {
      min-height: 40rem;
      h1 {
        margin-top: 1vh;
        margin-bottom: 1vh;
      }
      &__hr {
        &::after {
          height: 6.8vh;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    padding-top: 152px;
    &--article {
      #{$parent}__pic {
        margin-top: 3.1em;
      }
      #{$parent}__title {
        font-size: 5rem;
      }
      #{$parent}__pic {
        transform: translate(0, 2.6rem);
      }
      #{$parent}__footer {
        margin-top: 5em;
        padding: 4.8em 0 2.9em;
        &::before {
          margin-left: calc((-100vw + #{$width-xl}) * .5 - (#{$grid-gutter-width} * .5));
        }
      }
    }
    &--realisation {
      #{$parent}__row {
        padding-top: 2rem;
        padding-bottom: 3rem;
      }
      .quintilien {
        margin-top: 5rem;
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    padding-top: 166px;
    &--article {
      #{$parent}__row  {
        min-height: 51.7rem;
      }
      #{$parent}__pic-column  {
        min-height: 61.7rem;
      }
      #{$parent}__footer {
        &::before {
          margin-left: calc((-100vw + #{$width-xxl}) * .5 - (#{$grid-gutter-width} * .5));
        }
      }
    }
    &--realisation {
      #{$parent}__row {
        min-height: 37rem;
        padding-top: 5rem;
      }
      .quintilien {
        right: initial;
        left: ($grid-gutter-width * .5);
        margin-top: 3rem;
      }
    }
  }
  @media screen and (min-width: 1440px) {
    &__full-width-pic {
      aspect-ratio: initial;
    }
  }
}

