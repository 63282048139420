/**
 * https://micromodal.now.sh/
 */

.modal {
  $parent: &;
  display: none;
  &.is-open {
    display: block;
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(var(--bs-dark-rgb), .85);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__container {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    padding-top: 4rem;
    .container {
      height: 100%;
    }
  }
  &__scrollarea {
    overflow-y: auto;
    box-sizing: border-box;
    width: calc(100% + var(--bs-gutter-x));
    height: 100%;
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
  }
  &__title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25;
    box-sizing: border-box;
  }
  &__content {

  }

  @include media-breakpoint-up(sm) {
    &__container {
      height: 100vh;
    }
  }

  @include media-breakpoint-up(md) {
    &__container {
      max-height: 100vh;
    }
    @media screen and (min-height: 630px) {
      &__container {
        height: auto;
      }
    }
  }



}

@include media-breakpoint-up(md) {
  .modal-contact {
    .modal__container {
      padding-top: 4rem;
    }
  }
  .modal-fiche-trombi {
    .modal__container {
      padding-top: 6rem;
    }
  }
}



.btn-modal-close {
  z-index: 500;
  width: 60px;
  height: 60px;
  border: none;
  background: transparent;
  transition: height .5s ease;
  transition-delay: 1s;
  cursor: pointer;
  font-size: 1.1rem;
  color: var(--bs-white);
  transition: all .2s ease;
  transform: translate(0, -80%);
  span {
    height: 1px;
    width: 20px;
    background-color: currentColor;
    opacity: 1;
    left: 24px;
    transform: rotate(0deg);
    transition: all .25s ease-in-out;
    &:nth-child(1) {
      top: 20px;
    }
    &:nth-child(2),
    &:nth-child(3) {
      top: 28px;
    }
    &:nth-child(4) {
      top: 36px;
    }
  }
  &:hover,
  &:focus {
    color: #FFF;
  }
  &:focus {
    // border-color: $color-uic-green-1 !important;
  }
  &.active {
    z-index: 500;
    span {
      &:nth-child(1) {
        top: -20px;
        opacity: 0;
      }
      &:nth-child(2) {
        transform: rotate(45deg) translateZ(0);
      }
      &:nth-child(3) {
        transform: rotate(-45deg) translateZ(0);
      }
      &:nth-child(4) {
        top: 100px;
        opacity: 0;
      }
    }
  }
  @include media-breakpoint-up(md) {
    transform: translate(0, -100%);
    // .modal-fiche-trombi & {
    //   transform: translate(0, -125%);
    // }
    @media screen and (max-height: 629px) {
      transform: translate(0, -80%) !important;
    }
  }
}











// slide

@keyframes mmfadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.micromodal-fade {
  display: none;
  &.is-open {
    display: block;
  }
  &[aria-hidden=false] .modal__overlay,
  &[aria-hidden=false] .modal__container {
    animation: mmfadeIn .3s cubic-bezier(0,0,.2,1);
  }
  &[aria-hidden=true] .modal__overlay,
  &[aria-hidden=true] .modal__container {
    animation: mmfadeOut .3s cubic-bezier(0,0,.2,1);
  }
  .modal__container,
  .modal__overlay {
    will-change: transform;
  }
}
