/**
 * navigation
 */

.wrapper-nav {
  z-index: 999;
}




.main-nav {
  $parent: &;
  &__list {
    @include resetUl;
    > li {
      &:first-child {
        #{$parent}__link {
          padding-left: 0;
        }
      }
    }
  }
  &__link {
    position: relative;
    border: none;
    color: var(--bs-white);
    &::after {
      content: '';
      display: block;
      width: 0;
      height: 4px;
      background-color: var(--bs-white);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      transition: all .2s ease-in-out;
    }
  }
  &__text {
    position: relative;
    line-height: 1.1;
  }
}








// ================================================
//  <— MOBILE <— TABLET <— DESKTOP |
@include media-breakpoint-down(lg) {
  .hamburger {
    z-index: 500;
    width: 60px;
    height: 60px;
    border: none;
    background: transparent;
    transition: height .5s ease;
    transition-delay: 1s;
    cursor: pointer;
    font-size: 1.1rem;
    color: var(--bs-white);
    transition: all .2s ease;
    span {
      height: 1px;
      width: 20px;
      background-color: currentColor;
      opacity: 1;
      left: 24px;
      transform: rotate(0deg);
      transition: all .25s ease-in-out;
      &:nth-child(1) {
        top: 20px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 28px;
      }
      &:nth-child(4) {
        top: 36px;
      }
    }
    &:hover,
    &:focus {
      color: #FFF;
    }
    &:focus {
      // border-color: $color-uic-green-1 !important;
    }
    &.active {
      z-index: 500;
      span {
        &:nth-child(1) {
          top: -20px;
          opacity: 0;
        }
        &:nth-child(2) {
          transform: rotate(45deg) translateZ(0);
        }
        &:nth-child(3) {
          transform: rotate(-45deg) translateZ(0);
        }
        &:nth-child(4) {
          top: 100px;
          opacity: 0;
        }
      }
    }
  }
  #menu-principal {
    position: absolute !important;
    top: 59px;
    left: 0;
    z-index: 500;
    width: 100%;
    height: calc(100vh - 59px);
    opacity: 0;
    transition: opacity .2s ease-in-out;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: inherit;
    }
  }
  .main-nav {
    $parent: &;
    overflow: auto;
    margin: 0;
    padding: 0 0 1em;
    color: var(--bs-white);
    &__list {
      padding-top: calc(1em + 2vw);
      padding-right: ($grid-gutter-width * .5);
      padding-bottom: calc(1em + 2vw);
      padding-left: 8vw;
      > li {
        font-weight: 400;
      }
    }
    &__item {
      display: block;
      position: relative;
      font-size: 2rem;
      &.active {
        &::before {
          content: '';
          display: inline-block;
          width: 3rem;
          height: 1px;
          background-color: var(--bs-white);
          margin-right: 1rem;
        }
        #{$parent}__link {
          font-weight: 700;
        }
      }
    }
    &__link {
      display: inline-block;
      padding: .4em 0;
      color: var(--bs-white);
      &:hover,
      &:focus {
        color: var(--bs-white);
      }
    }
    &__icon {
      top: 1.75em;
      .icon {
        display: block;
        width: 100%;
        height: 100%;
        fill: currentColor;
        pointer-events: none;
      }
    }
    &__text {
      padding-right: 1.5em;
      position: static !important;
    }
  }
}














@include media-breakpoint-up(lg) {
  .wrapper-nav {
    height: 100%;
    padding-right: 5.6rem;
    .hamburger {
      display: none !important;
    }
    > .hidden-block {
      height: 100%;
    }
  }

  .main-nav {
    $parent: &;
    height: 100%;
    padding: 0;
    transition: all .6s ease;
    .pageload & {
      opacity: 0;
      transform: translate(0, -5%);
    }
    opacity: 1;
    transform: translate(0, 0);
    &__list {
      // width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: end;
      font-size: $font-size-base;
      transition: padding .2s ease-in-out;
      flex: 1 0 auto;
      > li {
        font-weight: 600;
        &:last-child {
          padding-right: 0;
        }
      }
    }
    &__item {
      flex: 0 0 auto;
      display: flex;
      align-items: stretch;
      justify-content: stretch;
      padding-right: 1.6em;
      transition: all .2s ease-in-out;
      &.active {
        #{$parent}__link {
          &::after {
            width: 100%;
          }
        }
      }
    }
    &__link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: 800;
      font-size: 1.4rem;
      color: var(--bs-white);
      transition: all .2s ease-in-out;
      &:hover,
      &:focus {
        color: var(--bs-white);
        &::after {
          width: 100%;
        }
      }
    }
    &__text {
      &::after {
        height: 5px;
        bottom: -33px;
      }
    }
    .header-sticky--active.header-sticky--down & {
      &__list {

      }
      &__text {
        &::after {

        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .main-nav {
    &__item {
      padding-left: .7em;
    }
  }
}
@include media-breakpoint-up(xxl) {
  .main-nav {
    &__item {
      padding-left: .9em;
    }
  }
}
