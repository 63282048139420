/**
 * pics
 */

// wordpress native positions
img {
  &.alignleft {
    clear: both;
    float: left;
    margin-right: 3rem;
    margin-bottom: 1em;
  }
  &.alignright {
    clear: both;
    float: right;
    margin-left: 3rem;
    margin-bottom: 1em;
  }
  &.aligncenter {
    clear: both;
    display: block;
    margin: 1em auto;
  }
}

.icon--90deg {
  transform: rotate(90deg);
}
.icon--180deg {
  transform: rotate(180deg);
}


.full-width {
  > img {
    position: relative;
    z-index: 55;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
  width: 100vw;
  margin-left: calc((-100vw + 100%) / 2);
  padding: 3em 0 0;
  .noTouchDevice & {
    width: calc(100vw - 9px);
  }
  // @include media-breakpoint-up(sm) {

  // }
  // @include media-breakpoint-up(md) {

  // }
  // @include media-breakpoint-up(lg) {

  // }
  // @include media-breakpoint-up(xl) {

  // }
  // @include media-breakpoint-up(xxl) {

  // }
}


// pics
figure {
  position: relative;
  z-index: 50;
  max-width: 840px;
  margin-top: 3em;
  margin-bottom: 3em;
  img {
    position: relative;
    z-index: 55;
    max-width: 100%;
    height: auto;
  }
  // [class*="col"] > & {
  //   &:last-child {
  //     margin-bottom: 1em;
  //   }
  // }
  .cell > & {
    margin-bottom: 1.4em;
  }
  // .airvance-slider--large-pics & {
  //   img {
  //     width: 100%;
  //     height: 374px;
  //     object-fit: contain;
  //   }
  // }
  // .legend,
  // .video-legend {
  //   @extend %legend;
  // }
  @include media-breakpoint-up(md) {
    .pic {
      display: block;
    }
    &[class*='float-end'],
    &[class*='float-start'] {
      clear: both;
      width: calc(50% + #{$grid-gutter-width} / 2);
      max-width: 51.6rem;
    }
    &[class*='float-end'] {
      float: right;
      margin-left: 3rem;
    }
    &[class*='float-start'] {
      float: left;
      margin-right: 3rem;
    }
  }
  @include media-breakpoint-up(lg) {
    &[class*='float-end'],
    &[class*='float-start'] {
      clear: both;

    }
    &[class*='float-end'] {
      clear: both;
      float: right;
      margin-left: 3rem;
      margin-bottom: 2em;
    }
    &[class*='start'] {
      clear: both;
      float: left;
      margin-right: 3rem;
      margin-bottom: 2em;
    }
  }
  @include media-breakpoint-up(xl) {
    &[class*='float-end'],
    &[class*='float-start'] {
      margin-top: .4em;
    }
    &[class*='float-end'] {
      margin-left: 4rem;
    }
    &[class*='float-start'] {
      margin-right: 4rem;
    }
  }
  @include media-breakpoint-up(xxl) {
    &[class*='float-end'],
    &[class*='float-start'] {
      width: calc(62.5% + #{$grid-gutter-width} / 2);
    }
    &[class*='float-end'] {
      margin-left: $grid-gutter-width;
    }
    &[class*='float-start'] {
      margin-right: $grid-gutter-width;
    }
    // .col-xxl-8 > & {
    //   margin-right: (-$width-xxl * .0833333333333333) !important;
    //   margin-left: (-$width-xxl * .0833333333333333) !important;
    // }
  }
  @include media-breakpoint-down(md) {
    &[class*='float-end'],
    &[class*='float-start'] {
      float: none !important;
      width: 100% !important;
    }
  }
}

.figure-max-width {
  max-width: 75rem;
}

// hr.clear-both {
//   clear: both;
//   visibility: hidden;
//   height: 1em;
//   margin: 2em 0;
// }

figure:not([class]) {
  margin-bottom: 1.4em;
  + h2,
  + h3,
  + h4,
  + h5,
  + h6 {
    margin-top: 3.5em;
  }
}
.figure {
  position: relative;
  z-index: 50;

}
.figure-caption {
  padding-top: .65em;
  .bg-primary & {
    color: inherit;
  }
}
.figure-icon {
  @extend %playIcon;
  a:hover &,
  a:focus &,
  *:hover > &,
  *:focus > & {
    background-color: var(--bs-primary) !important;
  }
}

p + figure:not([class]) {
  margin-top: 1.5em;
}


// .edito {
//   > img {
//     max-width: 100%;
//     height: auto;
//   }
// }

// .rounded-lg {
//   border-radius: $border-radius-lg;
// }

.figure-shapes {
  $parent: &;
  // margin-top: .7rem;
  &__shape {
    .icon {
      @extend %icon;
    }
    &--2 {
      width: 8.8rem;
      height: 8.8rem;
      z-index: 0;
      transform: translate(35%, -35%);
      opacity: .2;
      // color: var(--bs-dark) !important;
    }
  }
  &__content {
    position: relative;
    z-index: 5;
  }
  &--logo {
    #{$parent}__content {
      padding: 3rem;
      background-color: var(--bs-white);
    }
  }
  @include media-breakpoint-up(sm) {
    &--logo {
      #{$parent}__content {
        padding-top: 5rem;
        padding-bottom: 5rem;
      }
    }
  }
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {

  }
  @include media-breakpoint-up(xl) {
    &--logo {
      #{$parent}__content {
        padding-top: calc(2.719em + 3rem);
        padding-bottom: calc(2.719em + 3rem);
      }
    }
  }
}
section.bg-primary .figure-shapes__shape--2,
section.bg-secondary .figure-shapes__shape--2 {
  color: var(--bs-white);
}
section.bg-secondary .figure-shapes__shape--2 {
  opacity: 1;
}



// .figure-intro {
//   max-height: 533px;
//   &__shape {
//     width: 94px;
//     height: 94px;
//     z-index: 15;
//     .icon {
//       display: block;
//       width: 100%;
//       height: 100%;
//     }
//   }
//   &__inner {

//   }
//   &__pic {
//     z-index: 10;
//     img {
//       display: block;
//       width: 100%;
//       height: 100%;
//       object-position: 0 50%;
//       object-fit: contain;
//     }
//   }
//   &--portrait {
//     aspect-ratio: 1 / 1.0451;
//   }
//   &--paysage {
//     padding-top: 5.9rem;
//   }
// }
