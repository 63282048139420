/**
 * main header
 */

.main-header {
  // background-color: var(--bs-white);
  height: 100%;
  transition: all .2s ease;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: linear-gradient(270deg, #008ACD 0%, #764896 50.50%, #D61036 100%);
    opacity: 0;
    transition: all .2s ease;
  }
  > [class*="container"] {
    height: 100%;
    > .row {
      height: 100%;
    }
  }
  .page-contactez-nous &,
  .section-contactez-nous &,
  .page-contact-us &,
  .section-contact-us &,
  .header-sticky--active & {
    &::before {
      opacity: 1;
    }
  }
  @include media-breakpoint-up(lg) {
    height: 100%;
    // .header-sticky--active & {
    //   &::before {
    //     opacity: 1;
    //   }
    // }
    // body[style*="hidden"] & {
    //   background-color: var(--bs-white);
    // }
  }
}

.main-logo {
  display: inline-block;
  width: 138px;
  height: 30px;
  margin: .8rem 1.8rem 0 0;
  font-size: $font-size-base;
  line-height: 70%;
  color: currentColor;
  transition: all .2s ease;
  &-col {
    transition: all .2s ease;
  }
  .main-footer & {
    width: 200px !important;
    height: 31px !important;
  }
  transition: all .6s ease;
  .pageload & {
    opacity: 0;
  }
  opacity: 1;
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {
    width: 193px;
    height: 41px;
    margin-top: 0;
    margin-right: 0;
    &-col {
      padding-top: 3.1rem;
    }
  }
  @include media-breakpoint-up(xl) {

  }
  @include media-breakpoint-only(xl) {
    .main-footer & {
      width: 227px !important;
      height: 35px !important;
    }
  }
  @include media-breakpoint-up(xxl) {
    .main-footer & {
      width: 268px !important;
      height: 41px !important;
    }
  }
}

.i18n-wrapper {
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  padding-left: 8vw;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 800;
    font-size: 1.4rem;
    color: var(--bs-white);
    transition: all 0.2s ease-in-out;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 2.825em;
    transition: all .7s ease;
    .pageload & {
      opacity: 0;
      transform: translate(5%, -5%);
    }
    opacity: 1;
    transform: translate(0, 0);
  }
}

.simple-dropdown {
  border: none;
  padding-right: 1.8em;
  background-color: transparent;
  letter-spacing: .01em;
  .icon {
    width: 1.2rem;
    height: 1.2rem;
    fill: currentColor;
    top: 50%;
    transform: translate(0, -50%);
    transition: all .2s ease-in-out;
  }
}

.leaf-wrapper {
  transition: all .8s ease;
  .pageload & {
    opacity: 0;
    transform: translate(50%, 0);
  }
  opacity: 1;
  transform: translate(0, 0);
  .icon {
    width: 103px;
    height: 78px;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: none;
  }
}
