.field {
    &__label-group {
        display: flex;
        align-items: center;
        margin: 0 0 10px;
    }

    &__label {
        $label: &;
        display: block;
        color: black;
        font-size: 20px;
        line-height: 1.25;
        font-weight: 700;
        margin-bottom: 10px;
        padding-left: 23px;

        .required {
            color: #d61036;
        }

        &.disabled {
            color: color('grey', 'base');
        }
        &-radio {
            margin-bottom: 10px;

            @include media-breakpoint-up('medium') {
                margin-bottom: 15px;
            }
        }

        .field--inline > & {
            margin: .5rem 0;
        }

        & + .field__group,
        & + .field-repeater-fields {
            margin-top: 1.2rem;

            .field--inline > & {
                margin: .6rem 0;
            }
        }

        sup {
            color: #d61036;
        }
    }
}

label {
    .required {
        color: #d61036;
    }
}
