.tns-controls {
  [data-controls] {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    margin: 0 0 0 .4em;
    border: none; // border: 1px solid $primary;
    border-radius: 50%;
    padding: .5rem;
    background: var(--bs-white);
    transition: all .2s ease-in-out;
    .icon {
      @extend %icon;
      stroke: currentColor;
    }
    .section-gradient &,
    .section-gradient-oblique & {
      background: transparent;
    }
    &:not([disabled]) {
      &:hover,
      &:focus {
        background: var(--bs-primary);
        color: var(--bs-white);
        .section-gradient &,
        .section-gradient-oblique & {
          border: 1px solid var(--bs-white);
          background: transparent;
        }
      }
    }
  }
  [data-controls="next"] {
    .icon {
      transform: rotate(180deg);
    }
  }
  [disabled] {
    opacity: .5;
  }
  .modal-contact &,
  .section-implantations & {
    padding-top: 1rem;
    text-align: right;
  }
}

.tags-slider {
  .tns-controls {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    [data-controls="prev"],
    [data-controls="next"] {
      &::before {
        content: '<svg><use xlink:href="arrow"></use></svg>'
      }
    }
    [data-controls="prev"] {

    }
    [data-controls="next"] {

    }
  }
}

.tns-slider {
  .modal-contact &,
  .section-implantations & {
    display: flex;
    justify-content: start;
    align-items: stretch;
    margin-right: auto;
    margin-left: auto;
  }
}


.tns-slider {
  list-style: none;
  // .cell {
  //   &:last-child {
  //     padding-right: 0 !important;
  //   }
  // }
}

.tns-nav {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  position: relative;
  z-index: 45;
  width: 100%;
  padding: .2em 0 0;
  text-align: center;
  [aria-controls] {
    // @extend .btn-round;
    @extend .drop-shadow;
    margin: 5px;
    border: none;
    // border-radius: 50%;
    // box-shadow: 0 0 15px 1px rgba(0, 0, 0, .2);
    padding: 0;
    background-color: var(--bs-white);
    transition: all .2s ease-in-out;
    font-size: 2rem;
    color: var(--bs-gray-700);
    &.tns-nav-active {
      border: 1px solid var(--bs-gray-400);
      font-weight: 700;
      color: var(--bs-primary);
    }
    &:hover,
    &:focus {
      border: 1px solid var(--bs-gray-400);
      background-color: var(--bs-white);
      color: var(--bs-primary);
    }
  }
  counter-reset: tnsnav;
  [data-nav] {
    &::before {
      counter-increment: tnsnav;
      content: counter(tnsnav);
    }
  }
}


.regular-controls-row {
  .section-home-intro & {

  }
  @include media-breakpoint-up(xxl) {
    .section-home-intro & {
      max-width: 440px;
    }
  }
}

.regular-controls {

  // @include media-breakpoint-down(lg) {
  //   transform-origin: 100% 50%;
  //   transform: scale(.8);
  // }

  // @include media-breakpoint-up(md) {
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   z-index: 5;
  //   transform: translate(0, -5rem);
  // }
}

.regular-control {
  display: none;
  height: 4rem;
  &[data-controls] {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    margin: 0 0 0 .4em;
    border: none; // border: 1px solid $primary;
    border-radius: 50%;
    padding: .5rem;
    background: var(--bs-white);
    transition: all .2s ease-in-out;
    .icon {
      @extend %icon;
      stroke: currentColor;
    }
    // .section-home &,
    .section-gradient &,
    .section-gradient-oblique & {
      background: transparent;
    }
  }
  &[data-controls="next"] {
    .icon {
      transform: rotate(180deg);
    }
  }
  &[disabled] {
    opacity: .5;
  }
  &:not([disabled]) {
    &:hover,
    &:focus {
      background: $primary;
      color: var(--bs-white);
      .section-gradient &,
      .section-gradient-oblique & {
        border: 1px solid var(--bs-white);
        background: transparent;
      }
    }
  }
}



.airvance-slider {
  position: relative;
  z-index: 50;
  &--timeline {


  }
  &--trombi {
    display: flex;
  }
  @include media-breakpoint-up(sm) {

  }
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {
    &--timeline {

    }
  }
  @include media-breakpoint-up(xl) {

  }
  @include media-breakpoint-up(xxl) {

  }
}


.timeline-wrapper {
  &__spot {
    left: var(--tm-prog-width);
    z-index: 150;
    transform: translate(-80%, 38%);
    transition: left .2s ease-in-out;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
  }
  &::before {
    bottom: 4px;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 1px;
    background: var(--bs-dark);
  }
  &::after {
    bottom: 1px;
    height: 5px;
    z-index: 1;
    width: var(--tm-prog-width);
    background: var(--bs-primary);
    transition: width .2s ease-in-out;
  }
  @include media-breakpoint-up(lg) {
    &__spot,
    &::before {
      margin-left: 56px;
    }
    &::before {
      width: calc(100% - 56px);
    }
    &::after {
      left: 56px;
    }
  }
}

.trombi-wrapper {
  padding: 2rem 0 0;
  @include media-breakpoint-up(md) {
    padding: 0 5rem;
    .regular-control {
      .icon {

      }
      &[data-controls] {
        transform: translateY(-50%);
      }
      &[data-controls="prev"] {
        position: absolute;
        top: 11rem;
        left: 0;
        z-index: 5;
      }
      &[data-controls="next"] {
        position: absolute;
        top: 11rem;
        right: 0;
        z-index: 5;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    padding: 0 7rem 7rem;
  }
}


// [class*="col"].tns-item {
//   padding-right: 0 !important;
//   padding-left: 0 !important;
// }

// .section-home-intro {
//   .tns-outer {
//     width: 100vw;
//     margin-top: -.4em;
//     margin-left: math.div(-$grid-gutter-width, 2);
//     .tns-inner {
//       padding-left: (#{$grid-gutter-width} * .5);
//     }
//   }
//   @include media-breakpoint-up(sm) {
//     .tns-outer {
//       margin-left: calc(((-100vw + #{$width-sm}) / 2) - (#{$grid-gutter-width} * .5));
//       .tns-inner {
//         padding-left: calc(((100vw - #{$width-sm}) / 2) + (#{$grid-gutter-width} * .5));
//       }
//     }
//   }
//   @include media-breakpoint-up(md) {
//     .tns-outer {
//       width: calc(((math.div($width-md, 12) * 6) - $grid-gutter-width) + (((100vw - #{$width-md}) / 2) + (#{$grid-gutter-width} * .5)));
//       margin-top: -1.05rem;
//       margin-left: 0;
//       .tns-inner {
//         padding-left: 0;
//       }
//     }
//   }
//   @include media-breakpoint-up(lg) {
//     .tns-outer {
//       width: calc(((math.div($width-lg, 12) * 6) - $grid-gutter-width) + (((100vw - #{$width-lg}) / 2) + (#{$grid-gutter-width} * .5)));
//     }
//   }
//   @include media-breakpoint-up(xl) {
//     .tns-outer {
//       width: calc(((math.div($width-xl, 12) * 5) - $grid-gutter-width) + (((100vw - #{$width-xl}) / 2) + (#{$grid-gutter-width} * .5)));
//     }
//   }
//   @include media-breakpoint-up(xxl) {
//     .tns-outer {
//       width: calc(((math.div($width-xxl, 12) * 5) - $grid-gutter-width) + (((100vw - #{$width-xxl}) / 2) + (#{$grid-gutter-width} * .5)));
//     }
//   }
// }

// 10 / 2 = 10 * 1/2 = 10 * 0.5
// 10 / 3 = 10 * 1/3 = 10 * 0.3333333
// 10 / 12 = 10 * 1/12 = 10 * 0,0833333333333333


.full-slider {
  @include media-breakpoint-only(xs) {
    .tns-outer {
      width: 100vw;
      margin-left: (-$grid-gutter-width * .5);
      .tns-inner {
        padding-left: (#{$grid-gutter-width} * .5);
      }
    }
    .touchDevice & {
      .tns-outer {
        width: 100vw;
      }
    }
  }
  @include media-breakpoint-up(sm) {
    .tns-outer {
      width: 100vw;
      margin-left: calc(((-100vw + #{$width-sm}) / 2) - (#{$grid-gutter-width} * .5));
      .tns-inner {
        padding-left: calc(((100vw - #{$width-sm}) / 2) + (#{$grid-gutter-width} * .5));
      }
    }
    // .col-xl-10 {
    //   .tns-outer {
    //     margin-left: calc(((-100vw + #{$width-xl}) / 2) - (#{$width-xl} / 12));
    //     .tns-inner {
    //       padding-left: calc(((100vw - #{$width-xl}) / 2) + (#{$width-xl} / 12)  + (#{$grid-gutter-width} * .5));
    //     }
    //   }
    // }
  }
  @include media-breakpoint-up(md) {
    .tns-outer {
      width: 100vw;
      margin-left: calc(((-100vw + #{$width-md}) / 2) - (#{$grid-gutter-width} * .5));
      .tns-inner {
        padding-left: calc(((100vw - #{$width-md}) / 2) + (#{$grid-gutter-width} * .5));
      }
    }
    // .col-xl-10 {
    //   .tns-outer {
    //     margin-left: calc(((-100vw + #{$width-xl}) / 2) - (#{$width-xl} / 12));
    //     .tns-inner {
    //       padding-left: calc(((100vw - #{$width-xl}) / 2) + (#{$width-xl} / 12)  + (#{$grid-gutter-width} * .5));
    //     }
    //   }
    // }
  }
  @include media-breakpoint-up(lg) {
    .tns-outer {
      width: 100vw;
      margin-left: calc(((-100vw + #{$width-lg}) / 2) - (#{$grid-gutter-width} * .5));
      .tns-inner {
        padding-left: calc(((100vw - #{$width-lg}) / 2) + (#{$grid-gutter-width} * .5));
      }
    }
    // .col-xl-10 {
    //   .tns-outer {
    //     margin-left: calc(((-100vw + #{$width-xl}) / 2) - (#{$width-xl} / 12));
    //     .tns-inner {
    //       padding-left: calc(((100vw - #{$width-xl}) / 2) + (#{$width-xl} / 12)  + (#{$grid-gutter-width} * .5));
    //     }
    //   }
    // }
  }
  @include media-breakpoint-up(xl) {
    .tns-outer {
      width: 100vw;
      margin-left: calc(((-100vw + #{$width-xl}) / 2));
      .tns-inner {
        padding-left: calc(((100vw - #{$width-xl}) / 2));
      }
    }
    .justify-content-end {
      .col-xl-11 {
        .tns-outer {
          margin-left: calc(((-100vw + #{$width-xl}) / 2) - (#{$width-xl} / 12) - (#{$grid-gutter-width} * .5));
          .tns-inner {
            padding-left: calc(((100vw - #{$width-xl}) / 2) + (#{$width-xl} / 12)  + (#{$grid-gutter-width} * .5));
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    .tns-outer {
      width: 100vw;
      margin-left: calc(((-100vw + #{$width-xxl}) / 2) - (#{$grid-gutter-width} * .5));
      .tns-inner {
        padding-left: calc(((100vw - #{$width-xxl}) / 2) + (#{$grid-gutter-width} * .5));
      }
    }
    .justify-content-end {
      .col-xxl-10 {
        .tns-outer {
          margin-left: calc(((-100vw + #{$width-xxl}) / 2) - (#{$width-xxl} / 12 * 2) - (#{$grid-gutter-width} * .5));
          .tns-inner {
            padding-left: calc(((100vw - #{$width-xxl}) / 2) + (#{$width-xxl} / 12 * 2) + (#{$grid-gutter-width} * .5));
          }
        }
      }
    }
    // .col-xxl-8 {
    //   .tns-outer {
    //     margin-left: calc(((-100vw + #{$width-xxl}) / 2) - ((#{$width-xxl} / 12) * 2));
    //     .tns-inner {
    //       padding-left: calc(((100vw - #{$width-xxl}) / 2) + ((#{$width-xxl} / 12) * 2) + (#{$grid-gutter-width} * .5));
    //     }
    //   }
    // }
  }
}





.full-slider-mobile {
  @include media-breakpoint-only(xs) {
    .tns-outer {
      width: 100vw;
      margin-left: -2.5rem;
      .tns-inner {
        padding-left: 2.5rem;
      }
    }
    .touchDevice & {
      .tns-outer {
        width: 100vw;
      }
    }
  }
  @include media-breakpoint-only(sm) {
    .tns-outer {
      width: 100vw;
      margin-left: calc(((-100vw + #{$width-sm}) / 2) - (#{$grid-gutter-width} * .5));
      .tns-inner {
        padding-left: calc(((100vw - #{$width-sm}) / 2) + (#{$grid-gutter-width} * .5));
      }
    }
    .touchDevice & {
      .tns-outer {
        width: 100vw;
      }
    }
  }
  @include media-breakpoint-only(md) {
    .tns-outer {
      width: 100vw;
      margin-left: calc(((-100vw + #{$width-md}) / 2) - (#{$grid-gutter-width} * .5));
      .tns-inner {
        padding-left: calc(((100vw - #{$width-md}) / 2) + (#{$grid-gutter-width} * .5));
      }
    }
    .touchDevice & {
      .tns-outer {
        width: 100vw;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .tns-ovh {
      overflow: initial;
    }
  }
}
