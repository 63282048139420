.downloads {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 30px;

    &_title {
        font-size: 2rem;
        font-weight: 700;
    }

    &_description {
        font-size: 2rem;
        line-height: 150%;
        text-align: justify;
    }

    &_container_items {
        display: flex;
        flex-direction: column;
        row-gap: 22px;
        align-items: center;

        @media screen and (min-width: 488px) {
            flex-direction: row;
            column-gap: 140px;
            flex-wrap: wrap;
        }
    }

    &_item {
        display: flex;
        flex-direction: row;
        width: 100%;
        column-gap: 20px;
        text-decoration: none;

        @media screen and (min-width: 488px) {
            width: calc((100% - (1 * 140px))/2);
        }

        &::before {
            display: none !important;
        }

        &_titles {
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
        }

        &_title {
            font-size: 2rem;
            font-weight: 700;
            text-decoration: none;
        }

        &_subtitle {
            font-size: 2rem;
            text-decoration: none;
            color: rgb(87, 87, 87);
        }

        &_icon {
            position: relative;
            font-size: 4.2rem;
            color: rgb(87, 87, 87);

            &_extension {
                position: absolute;
                top: 17px;
                left: 15px;
                font-family: Helvetica, sans-serif;
                font-size: 1rem;
                font-weight: 700;
            }
        }
    }
}

.header-archive {
    flex-wrap: nowrap;
} 
