.field {
    &__file {
        position: relative;

        &-group {
            @include media-breakpoint-up(medium) {
                display: flex;
                align-items: center;
            }
        }

        &-input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        &-input:hover ~ .field__file-button,
        &-input:focus ~ .field__file-button {
            box-shadow: 0 2px 3px rgba(black, 0), inset 0px 3px 6px rgba(black, 0.16);
        }

        &-button {
            display: inline-flex;
            align-items: center;
            padding: 15px 30px;
            margin-bottom: 21px;
            border-radius: 0;
            background: #F8F8F8;
            box-shadow: 0 3px 6px rgba(black, 0.12), inset 0px 3px 6px rgba(black, 0);
            font-size: 18px;
            font-weight: 600;
            color: linear-gradient(225deg,#008acd 0,#764896 50.5%,#d61036 100%);
            cursor: pointer;
            transition: box-shadow 0.5s ease;

            @include media-breakpoint-up(medium) {
                margin-right: 21px;
                margin-bottom: 0;
            }

            &:hover,
            &:focus {
                box-shadow: 0 2px 3px rgba(black, 0), inset 0px 3px 6px rgba(black, 0.16);
            }

            .icon {
                color: color('primary', 'base');
                font-size: 30px;
                margin-right: 30px;
            }
        }

        &-button-filename {
            display: block;
            color: #939393;
            font-weight: 700;
            font-style: italic;
        }
    }
}
