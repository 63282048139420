/**
 *  CTA contact
 */

.cta-contact-mobile {
  @extend %linearGradient1;
}

@include media-breakpoint-down(lg) {
  .cta-contact-mobile {
    transition: all .4s ease-in-out;
    &-wrapper {
      height: 6rem;
      z-index: 800;
    }
    &__leaf {
      width: 103px;
      height: 78px;
      transform: translate(45%, -50%);
      pointer-events: none;
    }
    &:hover,
    &:focus {
      background: var(--bs-white) !important;
      color: var(--bs-primary) !important;
    }
  }
}
@include media-breakpoint-up(lg) {
  .cta-contact {
    transition: all .4s ease-in-out;
    &-wrapper {
      z-index: 800;
      bottom: 10vh;
      transition: all .6s ease-in-out;
      .footer-reached & {
        // opacity: 0;
        // z-index: -1;
        bottom: 5rem;
      }
    }
  }
}
