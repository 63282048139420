/**
 * main layout
 */

// .main-layout {

// }



.page {
  // z-index: 10;
  @extend .bg-white;
  // @include media-breakpoint-down(sm) {
  //   .container {
  //     padding-right: 2.5rem;
  //     padding-left: 2.5rem;
  //   }
  // }
  .row {
    clear: both;
  }
  main {
    // min-height: calc(100vh - 8.75rem);
    position: relative;
    z-index: 10;
    background-color: var(--bs-white);
    padding: 0 0 1px;
  }
  .home-main {
    padding-bottom: 6rem;
    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }
    @media (-moz-device-pixel-ratio: 1.25) {
      zoom: 1.25;
    }
    @media (-moz-device-pixel-ratio: 1.5) {
      zoom: 1.425;
    }
    @media (-webkit-device-pixel-ratio: 1.25) {
      zoom: 1.25;
    }
    @media (-webkit-device-pixel-ratio: 1.5) {
      zoom: 1.425;
    }
  }
}

// .section-free {
//   &.bg-primary,
//   &.bg-secondary,
//   &.bg-light,
//   &.bg-dark {
//     padding-top: 1.4em;
//     padding-bottom: .8em;
//   }
// }


// .section-aplat {
//   @include media-breakpoint-up(lg) {
//     margin-top: 3em !important;
//     margin-bottom: 5em !important;
//   }
// }

.section-gradient,
.section-gradient-oblique {
  @extend .text-white;
  padding: 3em 0 2em;
  @include media-breakpoint-up(lg) {
    padding-top: 7em;
    padding-bottom: 3.2em;
  }
}
.section-gradient {
  @extend %linearGradient1;
}
.section-gradient-oblique {
  @extend %linearGradient2;
}

.section-margin-rythm {
  margin: 3em 0;
  // @include media-breakpoint-up(lg) {
  //   margin-top: 5em;
  //   margin-bottom: 5em;
  // }
  // &:last-child {
  //   margin-bottom: 0;
  // }
  @include media-breakpoint-up(lg) {
    &.section-gradient {
      margin-top: 6.85em !important;
      margin-bottom: 6.85em !important;
    }
  }
  .section-anchors + & {
    margin-top: 0 !important;
  }
  // .sticky-playground > &:last-child {
  //   margin-bottom: 0 !important;
  // }
}

.section-padding-rythm {
  padding: 3em 0;
  @include media-breakpoint-up(lg) {
    padding-top: 6.85em;
    padding-bottom: 5em;
    + .section-margin-rythm {
      margin-top: 6.85em !important;
      margin-bottom: 6.85em !important;
    }
  }
  // .section-anchors + & {
  //   padding-top: 0 !important;
  // }
}

.section-timeline,
.section-vision,
.section-results {
  padding: 3em 0 0;
  @include media-breakpoint-up(lg) {
    padding-top: 6.85em;
  }
}

[class="section-padding-rythm"] + [class="section-padding-rythm"],
[class="section-free section-padding-rythm bg-white"] + [class="section-free section-padding-rythm bg-white"],
[class="section-free section-padding-rythm bg-light"] + [class="section-free section-padding-rythm bg-light"],
[class="section-free section-padding-rythm bg-primary text-white"] + [class="section-free section-padding-rythm bg-primary text-white"],
[class="section-free section-padding-rythm"] + [class="section-free section-padding-rythm"] {
  padding-top: 0 !important;
}


// .section-margin {
//   margin: 2em 0;
//   @include media-breakpoint-up(lg) {
//     margin-top: 3em;
//     margin-bottom: 3em;
//   }
//   &:first-child {
//     margin-top: 0;
//   }
//   .edito-intro + & {
//     margin-top: 0;
//   }
// }

// .container.no-mobile-margin {
//   @include media-breakpoint-down(sm) {
//     width: calc(100% + 40px); // width: calc(100vw);
//     margin-right: -20px;
//     margin-left: -20px;
//     padding-right: 0;
//     padding-left: 0;
//     .row {
//       margin-right: 0;
//       margin-left: 0;
//       padding: 0;
//     }
//     [class*="col"] {
//       padding-right: 0;
//       padding-left: 0;
//     }
//   }
// }

// .no-mobile-margin:not(.container) {
//   @include media-breakpoint-down(sm) {
//     width: calc(100% + 40px + #{$grid-gutter-width}); // width: calc(100vw);
//     margin-right: calc(-20px - (#{$grid-gutter-width} / 2));
//     margin-left: calc(-20px - (#{$grid-gutter-width} / 2));
//     padding-right: 0;
//     padding-left: 0;
//     .row {
//       margin-right: 0;
//       margin-left: 0;
//       padding: 0;
//     }
//     [class*="col"] {
//       padding-right: 0;
//       padding-left: 0;
//     }
//   }
// }

// @include media-breakpoint-down(sm) {
//   :root {
//     --bs-gutter-x: 40px !important;
//   }
//   .row {
//     --bs-gutter-x: 40px !important;
//   }
// }


// .offseted-column {
//   .col-lg-4 & {
//     @include media-breakpoint-up(xl) {
//       margin-left: -$grid-gutter-width;
//     }
//   }
// }
