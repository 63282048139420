/**
 * links
 */

// simple link
a {
  // font-weight: 700;
  // text-decoration: underline solid currentColor 1px;
  // p & {
  //   &.external,
  //   &:not([class]) {
  //     display: inline-block;
  //     // color: var(--bs-gray-900);
  //     text-decoration: none;
  //     background-image: linear-gradient(transparent calc(85% - 1px), var(--bs-primary) 1px, transparent calc(85% + 1px));
  //     background-repeat: no-repeat;
  //     background-color: transparent;
  //     background-position: 0 100%;
  //     background-size: 100% 100%;
  //     transition: all .2s ease-in-out;
  //     &:hover,
  //     &:focus {
  //       text-decoration: none;
  //       color: var(--bs-primary);
  //       background-image: linear-gradient(transparent calc(85% - 1px), var(--bs-red) 1px, transparent calc(85% + 1px));
  //     }
  //   }
  // }
  &:hover,
  &:focus {
    color: var(--bs-primary);
  }
}

.rs-link {
  text-decoration: none;
  .icon {
    display: inline-block;
    width: 2.8rem;
    height: 2.8rem;
    vertical-align: middle;
    fill: currentColor;
  }
  .section-gradient &,
  .section-gradient-oblique &,
  .main-footer &,
  .text-white & {
    @extend .text-white;
  }
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.shortcut-link,
.download-link {
  transition: all .2s ease-in-out;
  .btn-round {
    flex: 0 0 5rem;
  }
  &:hover,
  &:focus {
    .btn-round {
      background-color: var(--bs-secondary);
      color: var(--bs-white);
      padding-right: .7em;
      padding-left: .7em;
    }
  }
}

// .more-link {
//   @extend .d-inline-block;
//   @extend .fw-extrabold;
//   @extend .fs-8 ;
//   @extend .text-dark;
// }

// .shortcut-link {
//   appearance: none;
//   border: none;
//   padding: .75em .75em .75em 2em;
// }

// .download-link {
//   position: relative;
//   margin: 0 0 $grid-gutter-width;
//   text-decoration: none;
//   &__icon {
//     flex: 0 0 87px;
//     width: 87px;
//     height: 87px;
//     padding: 27px;
//     .icon {
//       display: block;
//       width: 100%;
//       height: 100%;
//       fill: currentColor;
//     }
//   }
//   &__content {
//     padding: 0 $grid-gutter-width;
//   }
//   &__name {
//     // flex: 0 0 100%;
//   }
//   &:hover,
//   &:focus {
//     text-decoration: underline solid currentColor 1px;
//   }
//   li:last-child > & {
//     margin-bottom: 0 !important;
//   }
//   @include media-breakpoint-down(md) {
//     &__icon {
//       flex: 0 0 47px;
//       width: 47px;
//       height: 47px;
//       padding: 10px;
//     }
//   }
// }

.download-link {
  $parent: &;
  position: relative;
  margin: 0;
  border-top: 1px solid var(--bs-gray-300);
  padding: 1rem 0;
  font-weight: 400;
  text-decoration: none;
  &__icon {
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    padding: 1.6rem;
    border-radius: $border-radius-sm;
    background: var(--degrad-angle, linear-gradient(225deg, #008ACD 0%, #764896 50.50%, #D61036 100%));
    color: var(--bs-white) !important;
    .icon {
      display: block;
      width: 100%;
      height: 100%;
      fill: currentColor;
      opacity: 0;
      transition: all .2s ease-in-out;
    }
  }
  &__content {
    padding: 0 2.2rem;
  }
  &__name {
    flex: 1 0 auto;
    color: var(--bs-primary);
    padding: 0 0 0 2rem;
  }
  &__extension,
  &__poids {
    color: var(--bs-gray-600);
  }
  &:hover,
  &:focus {
    #{$parent}__icon {
      .icon {
        opacity: 1;
      }
    }
    #{$parent}__name {
      text-decoration: underline solid currentColor 1px;
    }
  }
  li:last-child > & {
    margin-bottom: 0 !important;
  }
  @include media-breakpoint-down(md) {
    &__icon {
      flex: 0 0 47px;
      width: 47px;
      height: 47px;
      padding: 10px;
    }
  }
  li:last-child > & {
    border-bottom: 1px solid var(--bs-gray-300);
  }
}
.phone-link,
.mailing-link,
.website-link {
  color: currentColor;
  word-break: break-all;
  &:hover,
  &:focus {
    color: currentColor;
  }
  @include media-breakpoint-down(lg) {
    @include font-size(1.2rem);
  }
}
.mailing-link,
.website-link {
  font-weight: 700;
}

.phone-link,
.mailing-link {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.website-link {
  text-decoration: underline;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

// .btn-external,
// .link-external {
//   position: relative;
//   display: inline-block;
//   text-decoration: none;
//   color: var(--bs-primary);
//   &::before {
//     content: '';
//     display: block;
//     width: 1.5rem;
//     height: 1.5rem;
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     fill: currentColor;
//     background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#{$primary}" d="M20.2,23.8H2.5c-1.3,0-2.3-1-2.3-2.3V3.8c0-1.3,1-2.3,2.3-2.3h7.3c0.5,0,1,0.4,1,1s-0.4,1-1,1H2.5 c-0.2,0-0.3,0.1-0.3,0.3c0,0,0,0,0,0v17.7c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0h17.7c0.2,0,0.3-0.1,0.3-0.3c0,0,0,0,0,0v-7.3	c0-0.5,0.4-1,1-1s1,0.4,1,1v7.3C22.5,22.8,21.4,23.8,20.2,23.8z"/><path fill="#{$primary}" d="M23.8,1.2v7.2c0,0.5-0.4,1-1,1c-0.5,0-1-0.4-1-1V3.6l-9,9c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3 c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l9-9h-4.8c-0.5,0-1-0.4-1-1s0.4-1,1-1l0,0h7.2c0.1,0,0.1,0,0.2,0c0.4,0.1,0.7,0.4,0.8,0.8 C23.8,1.1,23.8,1.1,23.8,1.2z"/></svg>');
//     background-repeat: no-repeat;
//     background-color: transparent;
//     background-position: 50% 50%;
//     background-size: 100% 100%;
//     transition: all .2s ease-in-out;
//   }
// }
// .btn-external {
//   &:hover,
//   &:focus {
//     color: currentColor;
//     &::before {
//       background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.5 10.5"><path fill="#{$white}" d="M9.8,6.9c-0.4,0-0.7,0.3-0.7,0.6v1.6H1.3V1.3h1.6c0.4,0,0.7-0.3,0.7-0.7l0,0C3.5,0.3,3.3,0,2.9,0c0,0,0,0,0,0H0.7C0.3,0,0,0.3,0,0.7l0,0v9.2c0,0.4,0.3,0.7,0.7,0.7h9.2c0.4,0,0.7-0.3,0.7-0.7V7.6C10.5,7.2,10.2,6.9,9.8,6.9z"/><path fill="#{$white}" d="M9.8,0H7C6.6,0,6.3,0.3,6.3,0.7c0,0.4,0.3,0.7,0.6,0.7c0,0,0,0,0,0h1.2L4.6,4.9c-0.3,0.3-0.2,0.7,0,0.9C4.8,5.9,4.9,6,5.1,6 c0.2,0,0.3-0.1,0.5-0.2l3.6-3.7v1.3c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.6c0,0,0,0,0,0V0.7C10.5,0.3,10.2,0,9.8,0z"/></svg>');
//     }
//   }
// }
// .link-external {
//   padding-left: 2.4rem;
//   &::before {
//     left: .3rem;
//   }
// }
// .btn-external {
//   padding-left: 4.4rem;
//   &::before {
//     left: 2rem;
//   }
// }

// .back-link {
//   font-weight: 400;
//   font-size: 1.2rem;
//   color: var(--bs-secondary);
//   text-decoration: none;
//   transition: all .2s ease-in-out;
//   .icon {
//     width: 1.5rem;
//     height: 1.5rem;
//     margin: 0 1.6rem 0 0;
//     transform: rotate(180deg) translate(0, .15rem);
//     fill: currentColor;
//   }
//   &:hover,
//   &:focus {
//     text-decoration: underline;
//     color: var(--bs-secondary);
//   }
// }
