/**
 * section key figures
 */

.keyfigure {
  text-align: center;
  &__figure {
    display: block;
  }
  &__detail {
    display: block;
  }
  [class*="col"] > & {
    max-width: 38rem;
  }
}
