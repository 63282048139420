
/**
 * pagination
 */

.pagination {
  padding: 0 0 3em;
  a {
    padding-right: 1rem;
    padding-left: 1rem;
    font-weight: 500;
    color: var(--bs-body-color);
    text-decoration: none;
    transition: all .2s ease-in-out;
    &::after {
      content: '';
      display: block;
      width: 1.2rem;
      height: .2rem;
      background-color: var(--bs-primary);
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, 0);
      opacity: 0;
      transition: all .2s ease-in-out;
    }
    .icon {
      width: 2rem;
      height: 2rem;
      stroke-width: 2px;
      stroke: currentColor;
    }
    &[aria-current="true"] {
      font-weight: 700;
      color: var(--bs-primary);
      &::after {
        opacity: .8;
      }
    }
    &:hover,
    &:focus {
      color: var(--bs-primary);
      &::after {
        opacity: .8;
      }
    }
    &.prev,
    &.next {
      &::after {
        width: 75%;
      }
    }
    &.prev {
      .icon {
        margin-left: 0 !important;
      }
    }
    &.next {
      .icon {
        margin-right: 0 !important;
        transform: rotate(180deg);
      }
    }
    &:hover,
    &:focus {
      &::before {
        opacity: 1;
      }
    }
  }
  @include media-breakpoint-up(sm) {
    a {
      &::before {
        transform: translate(-50%, -1.9rem);
      }
    }
  }
  @include media-breakpoint-up(md) {
    // a {
    //   &.prev {
    //     padding-right: 1em;
    //   }
    //   &.next {
    //     padding-left: 1em;
    //   }
    // }
  }
  @include media-breakpoint-up(lg) {
    a {
      padding-right: .65em;
      padding-left: .65em;
    }
    &:last-child {
      padding-bottom: 4em !important;
    }
  }
  @include media-breakpoint-up(xl) {
    a {
      padding-right: .8em;
      padding-left: .8em;
    }
    &:last-child {
      padding-bottom: 5.4em !important;
    }
  }
}
