// @use 'sass:math';

/*!
 * Bootstrap v5.0.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import '../../../../../../node_modules/bootstrap/scss/functions';
@import 'abstracts/variables';
@import '../../../../../../node_modules/bootstrap/scss/variables';
@import '../../../../../../node_modules/bootstrap/scss/variables-dark';
@import '../../../../../../node_modules/bootstrap/scss/maps';
@import '../../../../../../node_modules/bootstrap/scss/mixins';
@import '../../../../../../node_modules/bootstrap/scss/utilities';

// Layout & components
@import '../../../../../../node_modules/bootstrap/scss/root';
@import '../../../../../../node_modules/bootstrap/scss/reboot';
@import '../../../../../../node_modules/bootstrap/scss/type';
@import '../../../../../../node_modules/bootstrap/scss/images';
@import '../../../../../../node_modules/bootstrap/scss/containers';
@import '../../../../../../node_modules/bootstrap/scss/grid';
// @import '../../../../../../node_modules/bootstrap/scss/tables';
@import '../../../../../../node_modules/bootstrap/scss/forms';
@import '../../../../../../node_modules/bootstrap/scss/buttons';
@import '../../../../../../node_modules/bootstrap/scss/transitions';
@import '../../../../../../node_modules/bootstrap/scss/dropdown';
// @import '../../../../../../node_modules/bootstrap/scss/button-group';
// @import '../../../../../../node_modules/bootstrap/scss/nav';
// @import '../../../../../../node_modules/bootstrap/scss/navbar';
// @import '../../../../../../node_modules/bootstrap/scss/card';
// @import '../../../../../../node_modules/bootstrap/scss/accordion';
@import '../../../../../../node_modules/bootstrap/scss/breadcrumb';
// @import '../../../../../../node_modules/bootstrap/scss/pagination';
// @import '../../../../../../node_modules/bootstrap/scss/badge';
// @import '../../../../../../node_modules/bootstrap/scss/alert';
// @import '../../../../../../node_modules/bootstrap/scss/progress';
// @import '../../../../../../node_modules/bootstrap/scss/list-group';
// @import '../../../../../../node_modules/bootstrap/scss/close';
// @import '../../../../../../node_modules/bootstrap/scss/toasts';
// @import '../../../../../../node_modules/bootstrap/scss/modal';
// @import '../../../../../../node_modules/bootstrap/scss/tooltip';
// @import '../../../../../../node_modules/bootstrap/scss/popover';
// @import '../../../../../../node_modules/bootstrap/scss/carousel';
// @import '../../../../../../node_modules/bootstrap/scss/spinners';
// @import '../../../../../../node_modules/bootstrap/scss/offcanvas';
// @import '../../../../../../node_modules/bootstrap/scss/placeholders';

// Helpers
@import '../../../../../../node_modules/bootstrap/scss/helpers';

// Utilities
@import '../../../../../../node_modules/bootstrap/scss/utilities/api';
// scss-docs-end import-stack








// abstracts/   handles function, mixins, and variables
// base/        animations, base, typography, and utilies.
// components/  have a single scss file for each individual component
// layout/      Header, footer, grid, navigation
// pages/       Have a single scss for each specific page
// themes/      deals with various themes (optional, I haven’t use it yet)
// vendor/      handles 3rd party css


// ###########################################
// ################## MISC ###################
// ###########################################

// https://stackoverflow.com/questions/25673707/ie11-triggers-css-transition-on-page-load-when-non-applied-media-query-exists
.pageload {
  * {
    transition: none !important;
  }
}



// ###########################################
// ################### META ##################
// ###########################################

@import '../../../../../../node_modules/sass-svg-uri/svg-uri';
@import '../../../../../../node_modules/animate-sass/animate';
@import '../../../../../../node_modules/tiny-slider/src/tiny-slider';
@import 'abstracts/mixins';
@import 'base/_colors';
@import 'base/fonts';
@import 'base/meta';
@import 'base/buttons';
@import 'base/edito';
@import 'base/form';
@import 'base/dropdown';
@import 'base/titles';
@import 'base/lists';
@import 'base/pics';
@import 'base/links';
@import 'base/breadcrumb';
// @import '../../../../../../node_modules/aos/dist/aos';
@import 'vendor/aos';
@import 'vendor/tiny-slider';
// @import '../../../../../../node_modules/glightbox/src/postcss/glightbox';
@import 'vendor/glightbox';
@import 'vendor/micromodal';


// ###########################################
// ################# SKELETON ################
// ###########################################

@import 'layout/breakpoints';
@import 'layout/skip';
@import 'layout/global-overlay';
@import 'layout/main-header';
@import 'layout/sticky';
@import 'components/rs-list';
@import 'layout/main-nav';
@import 'layout/main-layout';
@import 'layout/main-intro';
@import 'layout/odd-intro';
@import 'layout/anchors-nav';

@import 'layout/oddlayout';
@import 'layout/cta-contact';
@import 'layout/main-footer';

@import 'parts/spinner';


// ###########################################
// ################# CONTENT #################
// ###########################################


@import 'components/section-home';

@import 'components/tile-timeline';
@import 'components/downloads';
@import 'components/tile-trombi';
@import 'components/tile-pillar';
@import 'components/tile-news';
@import 'components/tile-testimony';
@import 'components/spacing';

@import 'components/quintilien';

@import 'components/section-keyfigures';

@import 'components/tags';
@import 'components/table';
@import 'components/transversal-nav';
@import 'components/implantations';
@import 'components/pagination';

@import 'layout/overridebs';
@import 'layout/print';

@import 'forms/checkbox';
@import 'forms/container';
@import 'forms/errors';
@import 'forms/feedback';
@import 'forms/fieldset';
@import 'forms/file';
@import 'forms/group';
@import 'forms/input';
@import 'forms/label';
@import 'forms/message';
@import 'forms/radio';
@import 'forms/select';
@import 'forms/switch';
@import 'forms/textarea';