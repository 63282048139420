.form-send {
    position: relative;
    max-width: 980px;
    width: 100%;
    margin: 50px auto 0;
    padding: 50px 12px 20px;
    background: color('background', 'grey');
    border-radius: 21px;
    text-align: center;
    box-shadow: $box-shadow;

    @include media-breakpoint-up(medium) {
        margin-top: 70px;
        padding: 50px 20px 40px;
    }

    &__content-icon {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 106px;
        height: 106px;
        border: 1px solid color('primary', 'base');
        border-radius: 50%;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        background: color('primary', 'base');
        border-radius: 50%;
        color: $white;
        font-size: 23px;
    }

    p {
        text-align: center;
    }

    &__cta {
        margin-top: 20px;
    }
}

.form_pc_confirm_container {
    display: none;
}