/**
 * transversal navigation
 */

.nav-transversale-wrapper {
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 100vw;
    height: 1px;
    background: linear-gradient(270deg, #008ACD 0%, #764896 50.50%, #D61036 100%);
    backdrop-filter: blur(7.5px);
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 5;
    transform: translateX(-50%);
  }
  @include media-breakpoint-up(md) {
    [class*="col"] + [class*="col"] {
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background-color: #754997;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 5;
        transform: translateY(-50%);
      }
    }
  }
}
