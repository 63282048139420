/**
 *  PRINT
 */

@media print {
  .page {
    padding: 0 !important;
  }
  #mainMenuButton,
  #mainSearchButton,
  .global-overlay,
  .wrapper-header,
  .main-footer,
  .share-set {
    display: none !important;
  }
  main {
    flex: 100%;
    max-width: 100% !important;
  }
}
